<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'

export default {
  name: 'IoShouldNotBeInOtto',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        {
          label: 'Failed checks',
          value: (alert) => {
            return `<br> -check rev_CPC : ${this.$commonUtils.htmlEntities(alert.data.check_rev_CPC)}
                    <br> -check cost_plus_with_impr_budget : ${this.$commonUtils.htmlEntities(alert.data.check_cost_plus_with_impr_budget)}`
          },
          endLabel: '',
          roundValue: false,
          isPerc: false,
          colorize: false,
          isDate: false
        }
      ],
      settingsData: [
      ],
      algoStrategyData: [
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
