import Component from 'vue-class-component'
import Vue from 'vue'
import AlertModel from '@/models/Alertings/AlertModel'

/**
 * Use this mixin with tooltip value who are async loaded.
 * Call loadToolTipText on the mouseenter event on the element who trigger the tooltip (the element with v-on)
 * "func" of loadToolTipText argument should be a async function. The function can be pass directly
 * as a parameters of the function, but if you want to pass some arg to the async fonction,
 * use a callback function like in the example.
 *
 * ex :
 * <v-tooltip bottom>
     <template v-slot:activator="{ on }">
      <v-icon @mouseenter="loadToolTipText(async () => {
        return myAsyncFunc(someArg)
     }, item.key)" v-on="on" :class="'info-status-icon' + ' color-' + action.icon">{{action.icon}}</v-icon>
     </template>
     <span>{{ getToolTipText(item.key) }}</span>
   </v-tooltip>
 * Live example in AlertsTable.vue and AlertSideData.vue
 */
@Component
export class LoadTooltipTextMixin extends Vue {
  tooltipText: Record<string | number, any> = {}

  async loadToolTipText (func: Function, tooltipKey: string | number) {
    // if the key has been already computed, do nothing
    if (this.tooltipText[tooltipKey]) {
      return
    }
    let value = await func()
    this.$set(this.tooltipText, tooltipKey, value)
  }

  getToolTipText (tooltipKey: string | number) {
    return this.tooltipText[tooltipKey] ? this.tooltipText[tooltipKey] : 'Load ...'
  }

  /**
   * adaptation of the getToolTipText function, to be used with the action and alert item
   * @param action
   * @param item
   */
  getToolTipTextAction (action: StatusAction, item: AlertModel[]) {
    let key = item[0].keyFrom + action.action
    return this.getToolTipText(key)
  }
  /**
   * adaptation of the loadToolTipText function, to be used with the action and alert item
   * @param action
   * @param item
   */
  async loadToolTipTextAction (action: StatusAction, item: AlertModel[]) {
    let key = item[0].keyFrom + action.action
    if (typeof action.done_text === 'function') {
      await this.loadToolTipText(async () => {
        // we know done_text is a function here (type is test just before)
        return (action.done_text as Function)(item)
      }, key)
    } else {
      this.$set(this.tooltipText, key, action.done_text)
    }
  }
}
