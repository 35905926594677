<template>
  <div>
    <div>
      <v-row no-gutters>
        <v-col cols="7">
          <div>{{alert.dateToLocal()}}</div>

          <div><strong>Alert id : </strong><span class="alertId-span">{{alert.id}}</span></div>

          <v-alert
            :value="isExpired"
            type="warning"
            icon="priority_high"
            outlined
            class="alert-warning-side-data"
          >
            This alert is expired.
          </v-alert>

          <div>
            <component v-bind:is="getComponentName(alert.error_type)"
                        :alert="alert"
                        :checkPreMepResult="checkPreMepResult"
            />
          </div>

        </v-col>

        <v-col cols="5" class="alert-side-info">

          <v-row no-gutters>

            <div v-if="alert.hasNoStatus()">
              <div class="no-status-alert">
                No status
              </div>
            </div>

            <v-col
              v-else
              v-for="(action, index) in getStatusAction"
              v-bind:key="index + 'icon'"
            >
              <div v-if="alert.is(action.action) || (action.action === 'false_positive' && alert.wasFalsePositive())" v-bind:key="index + '_status'">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :class="'button-status-alert ' + (selectedStatus === action.action ? 'status-selected' : '')"
                      icon
                      @click="setSelectedStatus(action.action)">
                      <v-icon @mouseenter="loadToolTipTextAction(action, [alert])" v-on="on" :class="'info-status-icon' + ' color-' + action.icon">
                        {{action.icon}}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{  getToolTipTextAction(action, [alert])  }}</span>
                </v-tooltip>
              </div>
            </v-col>

          </v-row>

          <div v-if="(alert.getStatusList()).length >= 1" class="info-user-status">
            Click for display info about the status
          </div>

          <div class="status-detail-div">
            <div v-if="selectedStatus !== null">
              {{  selectedStatus === 'attributed' ? 'Attributed by' : 'Treater'}} : {{alert.getMessageByForStatus(selectedStatus)}}
            </div>
          </div>

          <div class="status-detail-div" v-if="selectedStatus !== null && alert.getContentForStatus(selectedStatus)">
            <div>
              Text : {{alert.getContentForStatus(selectedStatus)}}
            </div>
          </div>

          <div class="status-detail-div" v-if="selectedStatus !== null && ['attributed', 'fixed'].indexOf(selectedStatus) !== -1">
            <div>
              {{selectedStatus === 'fixed' ? 'Fixed by' : 'Attributed to' }} : {{alert.getMessageToForStatus(selectedStatus)}}
            </div>
          </div>

          <div class="status-detail-div">
            <div v-if="selectedStatus !== null">
              Date : {{$transformIsoDate(alert[selectedStatus + '_last_update'])}}
            </div>
          </div>

          <div class="ultra-detailed-div" v-if="hasErrorUltraDetailedData(alert.error_type)">
            <v-btn
              icon
            >
              <v-icon @click="openUltraDetailed()" class="icon-info-alertdata">info</v-icon>
            </v-btn>
            <UltraDetailedDialog
              v-model="dialogUltraDetailed"
              :alert="alert"
            >
            </UltraDetailedDialog>
          </div>

          <div v-if="hasNotes">
            <v-btn icon>
              <v-icon @click="openNotes()" class="icon-message-alertnotes">message</v-icon>
            </v-btn>
          <IONotesDialog v-model="dialogNotes"
                         :notes="notes">
          </IONotesDialog>
        </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { LoadingTemplate } from './AlertTypeTemplate'
import IONotesDialog from '@/components/AlertingComponents/Sheet/SheetFragments/UltraDetailedTemplate/IONotesDialog'
import { LoadTooltipTextMixin } from '@/mixins/loadTooltipTextMixin'
import AlertModel from '@/models/Alertings/AlertModel'

export default {
  name: 'AlertSideData',
  props: {
    alert: {
      type: AlertModel
    },
    isExpired: {
      type: Boolean
    },
    notes: {
      type: Array,
      default: function () {
        return []
      }
    },
    // @type {CheckPreMepResult}
    checkPreMepResult: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  components: {
    IONotesDialog,
    // TODO add loading and error gestion in all template ?
    LoadingTemplate,
    UltraDetailedDialog: () => import('./UltraDetailedTemplate/UltraDetailedDialog.vue'),
    ScrollableTable: () => import('../../../Common/ScrollableTable.vue'),
    ABTestEnded: () => import('./AlertTypeTemplate/ABTestEnded.vue'),
    AlertingsMargin: () => ({
      component: import('./AlertTypeTemplate/AlertingsMargin'),
      loading: LoadingTemplate,
      error: '<div>Error</div>',
      delay: 100,
      timeout: 3000
    }),
    BaseBrief: () => import('./AlertTypeTemplate/BaseBrief'),
    ChronicAlert: () => import('./AlertTypeTemplate/ChronicAlert.vue'),
    CollisionOnCampaigns: () => import('./AlertTypeTemplate/CollisionOnCampaigns.vue'),
    CollisionOnOtto: () => import('./AlertTypeTemplate/CollisionOnOtto.vue'),
    CpmTooLowOrHight: () => import('./AlertTypeTemplate/CpmTooLowOrHight.vue'),
    SubOptimalConsoleSettings: () => import('./AlertTypeTemplate/SubOptimalConsoleSettings.vue'),
    DefaultAlertType: () => import('./AlertTypeTemplate/DefaultAlertType.vue'),
    FarmingAlert: () => import('./AlertTypeTemplate/FarmingAlert.vue'),
    Fuck: () => import('./AlertTypeTemplate/Fuck.vue'),
    FuckFuckFuckFuck: () => import('./AlertTypeTemplate/FuckFuckFuckFuck.vue'),
    HeterogeneousBusinessModels: () => import('./AlertTypeTemplate/HeterogeneousBusinessModels.vue'),
    IoShouldNotBeInOtto: () => import('./AlertTypeTemplate/IoShouldNotBeInOtto.vue'),
    IoHasBlockingEditableProperties: () => import('./AlertTypeTemplate/IoHasBlockingEditableProperties.vue'),
    IoStuckInComputing: () => import('./AlertTypeTemplate/IoStuckInComputing.vue'),
    IncoherenceInDatastoreSettingsV2: () => import('./AlertTypeTemplate/IncoherenceInDatastoreSettingsV2.vue'),
    IntradayPacingOffTracks: () => import('./AlertTypeTemplate/IntradayPacingOffTracks.vue'),
    InstructionInDraft: () => import('./AlertTypeTemplate/InstructionInDraft'),
    InstructionToValidate: () => import('./AlertTypeTemplate/InstructionToValidate'),
    KpiTooLowOrHight: () => import('./AlertTypeTemplate/KpiTooLowOrHight.vue'),
    MarginTooLowVs: () => import('./AlertTypeTemplate/MarginTooLowVs.vue'),
    OptimizationMainPixels: () => import('./AlertTypeTemplate/OptimizationMainPixels.vue'),
    OttoShouldBeActivated: () => import('./AlertTypeTemplate/OttoShouldBeActivated.vue'),
    Overdelivery: () => import('./AlertTypeTemplate/Overdelivery.vue'),
    MarkupModuleAlert: () => import('./AlertTypeTemplate/MarkupModuleAlert'),
    MarkupModuleSettings: () => import('./AlertTypeTemplate/MarkupModuleSettings'),
    PerfFirstDeliveryDrop: () => import('./AlertTypeTemplate/PerfFirstDeliveryDrop'),
    PerfFirstNoDelivery: () => import('./AlertTypeTemplate/PerfFirstNoDelivery'),
    PerfPerformanceIsOffTrack: () => import('./AlertTypeTemplate/PerfPerformanceIsOffTrack'),
    PixelNoLongerDetected: () => import('./AlertTypeTemplate/PixelNoLongerDetected'),
    ShouldntBeInOttoV2: () => import('./AlertTypeTemplate/ShouldntBeInOttoV2.vue'),
    SubOptimalConsoleSettingsTrueview: () => import('./AlertTypeTemplate/SubOptimalConsoleSettingsTrueview.vue'),
    TargetingWebAndApp: () => import('./AlertTypeTemplate/TargetingWebAndApp.vue'),
    TemplateBriefNotActivatedOrValidated: () => import('./AlertTypeTemplate/TemplateBriefNotActivatedOrValidated.vue'),
    UncompleteIos: () => import('./AlertTypeTemplate/UncompleteIos.vue'),
    Underdelivery: () => import('./AlertTypeTemplate/Underdelivery.vue'),
    UnderdeliveryEndOfBilling: () => import('./AlertTypeTemplate/UnderdeliveryEndOfBilling.vue'),
    UnderdeliveryMaxAMaxCpm: () => import('./AlertTypeTemplate/UnderdeliveryMaxAMaxCpm.vue'),
    UnsupportedBusinessModels: () => import('./AlertTypeTemplate/UnsupportedBusinessModels.vue'),
    ValidatedNotPassingCheckPremep: () => import('./AlertTypeTemplate/ValidatedNotPassingCheckPremep.vue'),
    ValidatedIOIncoherentCheckPremep: () => import('./AlertTypeTemplate/ValidatedIOIncoherentCheckPremep.vue'),
    ViewRateTooLowVsAcceptedThresholds: () => import('./AlertTypeTemplate/ViewRateTooLowVsAcceptedThresholds.vue'),
    WrongSyntax: () => import('./AlertTypeTemplate/WrongSyntax.vue'),
    LimitingMaxA: () => import('./AlertTypeTemplate/LimitingMaxA')
  },
  mixins: [
    LoadTooltipTextMixin
  ],
  loading: LoadingTemplate,
  data: function () {
    return {
      selectedStatus: null,
      ultraDetailedData: [
        {}
      ],
      dialogUltraDetailed: false,
      dialogNotes: false,
      hasNotes: false
    }
  },
  created: function () {

  },
  mounted: function () {
  },
  methods: {
    setSelectedStatus: function (status) {
      this.selectedStatus = status
    },
    getComponentName: function (errorType) {
      // DEFAULT COMPONENT IF DONT EXIST
      let component = (this.$getInfoError(errorType)).component
      return component !== null ? component : 'DefaultAlertType'
    },
    openUltraDetailed: function () {
      this.dialogUltraDetailed = true
    },
    openNotes: function () {
      this.dialogNotes = true
    },
    hasErrorUltraDetailedData: function (errorType) {
      let info = this.$getInfoError(errorType)
      return info.ultraDetailedData !== null
    }
  },
  computed: {
    ...mapGetters(['isUserDebugger', 'getStatusAction'])
  },
  watch: {
    'alert': {
      immediate: true,
      deep: true,
      handler: function () {
        this.selectedStatus = null
        if (!this.alert.hasNoStatus()) {
          let actionList = this.getStatusAction

          for (let i in actionList) {
            let action = actionList[i].action

            if (this.alert.is(action)) {
              this.setSelectedStatus(action)
              break
            }
          }
        }
      }
    },
    'notes': {
      immediate: true,
      deep: true,
      handler: function () {
        this.hasNotes = this.notes.length > 0
      }
    }
  }
}
</script>

<style>

  .alert-side-info div {
    padding: 0.3em;
  }

  .ultra-detailed-data {
    height: 15em;
    width: 15em;
    background: white;
    padding: 1em;
  }

  .ultra-detailed-div div {
    border: none;
  }

  .icon-info-alertdata {
    color: #2b85e7 !important;
  }

  .icon-message-alertnotes {
    color: #2b85e7 !important;
  }

  .button-status-alert {

  }

  .status-detail-div {
    min-height: 3.5em;
  }
  button.v-btn.v-btn--icon.theme--light.button-status-alert.status-selected {
    background: gainsboro;
  }

  .info-user-status {
    font-style: italic;
    font-size: 8px;
    margin-bottom: 1em;
  }

  .no-status-alert {
    font-style: italic;
    font-size: 8px;
  }

  .dialog-ultra-detailed {
    background-color: #fff;
  }

  .header-ultra-detailed {
    padding: 1em;
  }

  .alertId-span {
    font-size: 9px;
    font-style: italic;
  }

  .body-ultra-detailed {

  }
  .border-col-span {
    border-bottom: 2px #acb8c8 solid;
    border-left: 2px #acb8c8 solid;
    border-top: 2px #acb8c8 solid;
  }

  .border-top-ultra {
    border-top: 2px #acb8c8 solid;
  }

  .border-bottom-ultra {
    border-bottom: 2px #acb8c8 solid;
  }

  .border-right-ultra {
    border-right: 2px #acb8c8 solid;
  }

  .v-alert.alert-warning-side-data {
    font-size: 8px;
    padding: 5px;
  }

  .v-alert.alert-warning-side-data .v-alert__icon.v-icon {
    font-size: 12px;
  }

  /* Large screens ----------- */
  @media only screen
  and (min-width : 1824px) {
    .info-user-status {
      font-size: 9px;
    }
  }
</style>
