<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'

export default {
  name: 'Overdelivery',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        { label: 'Alert level', value: 'data.concerned_lvl', endLabel: '', roundValue: false, isPerc: false, colorize: false, isDate: false },
        { label: 'Consecutive days worse case', value: 'data.max_nb_days', endLabel: '', roundValue: false, isPerc: false, colorize: false, isDate: false },
        { label: 'Overdelivery D-1', value: 'data.max_overdelivery', endLabel: '', roundValue: false, isPerc: false, colorize: false, isDate: false }
      ],
      algoStrategyData: [
        { label: 'Otto status when alert was calculating', value: 'settings.opti_auto', endLabel: '', roundValue: false, isPerc: false, colorize: false, isDate: false },
        { label: 'History of Otto status', value: 'data.otto_status_since', endLabel: '', roundValue: false, isPerc: false, colorize: false, isDate: false }
      ],
      settingsData: [
        { label: 'Limit score', value: 'settings.limit_score', endLabel: '', roundValue: false, isPerc: false, colorize: false, isDate: false },
        { label: 'Overdelivery custom', value: 'settings.overdelivery_custom', endLabel: '', roundValue: false, isPerc: false, colorize: false, isDate: false }
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {
  },
  watch: {

  }
}
</script>

<style>
</style>
