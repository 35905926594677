<template>
  <BaseCardDialog
    v-model="dialog"
    title="Action"
    :fullWidth="false"
    width="50%"
    :is-loaded="isInProcess"
    message="Bulk edit in process..."
  >
    <template v-slot:content>
      <v-row>
        <v-alert class="info-action-dialog" type="info">
          You are going to apply the action to <strong>{{$store.getters.getNumberOfLineSelectedBuckets}}</strong> alerts
        </v-alert>
      </v-row>

      <v-row align-center justify-center>
        <v-btn
          @click="$emit('call-buckets-update', 'done', null, false, true)"
        >
          Set all as Done
        </v-btn>
        <v-btn
          @click="$emit('call-buckets-update', 'false_positive', null, false, true)"
        >
          Set all as False Positive
        </v-btn>
        <v-btn
          @click="$emit('call-buckets-update', 'help', null, false, true)"
        >
          Set all as Help
        </v-btn>
      </v-row>

    </template>
  </BaseCardDialog>
</template>

<script>
import BaseCardDialog from '../../Common/BaseCardDialog'

export default {
  name: 'AlertActionDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isInProcess: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BaseCardDialog
  },
  data: function () {
    return {
      dialog: false
    }
  },
  created: function () {

  },
  mounted: function () {
    this.dialog = this.value
  },
  methods: {
  },
  computed: {
  },
  watch: {
    value (val) {
      if (val !== this.dialog) {
        this.dialog = val
      }
    },
    dialog (val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style>
  .info-action-dialog {
    width: 80%;
    margin-bottom: 2em;
  }
</style>
