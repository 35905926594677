<template>
  <v-row row>
    <v-col>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            @click="$emit('open-summary')"
            text
            icon
            v-on="on"
          >
            <v-icon>list</v-icon>
          </v-btn>
        </template>
        <span>Show debugger summary</span>
      </v-tooltip>
    </v-col>

    <v-col>
      <v-tooltip :value="isActionEnabled" bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              @click="$emit('open-action')"
              text
              icon
              :disabled="!isActionEnabled"
              :class="{ 'action-button-enabled': isActionEnabled }"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </div>
        </template>
        <span>Action : Use it for buckets edit</span>
      </v-tooltip>
    </v-col>

    <v-col>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            @click="$emit('refresh-data')"
            text
            icon
            v-on="on"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh the data</span>
      </v-tooltip>
    </v-col>
    <v-col v-if="newAlerts && newAlerts.length">
      <v-menu class="offset-y">
        <template v-slot:activator="{ on }">
          <v-badge
            color="red"
            :value="newAlerts.length"
            class="custom-pos"
          >
            <v-btn v-on="on">NEW ALERTS</v-btn>
            <template v-slot:badge>
              <span>{{newAlerts.length}}</span>
            </template>
          </v-badge>
        </template>
        <v-list class="list-new-alert">
          <v-list-item
            v-for="(item, index) in newAlerts"
            :key="index"
            @click="updateSearch(item)"
          >
            <v-list-item-avatar size="30">
              <img alt="dsp image" :src="require('../../../assets/' + item.group_key.dsp + '.png')">
            </v-list-item-avatar>
            <v-list-item-title>
                {{ item.group_key.insertion_order_id }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ToolBox',
  props: [],
  components: {

  },
  data: function () {
    return {

    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    /**
     * @param gkModel {GroupKeyModel}
     */
    updateSearch (gkModel) {
      this.newAlerts = this.newAlerts.filter(a => a && a.id !== gkModel.id)
      this.$emit('displayLastAlert', gkModel.group_key.insertion_order_id)
    }
  },
  computed: {
    isActionEnabled () {
      return this.$store.getters.getBucketsAlertsId.length > 0
    },
    newAlerts: {
      get: function () {
        return this.$store.getters.getNewAlerts
      },
      set: function (value) {
        this.$store.commit('setLoadNewAlerts', value)
      }
    }
  },
  watch: {

  }
}
</script>

<style>
  .action-button-enabled {
    background: #60A1F0;
    color: white !important;
  }
  .list-new-alert {
    min-width: 200px;
  }

  .custom-pos .v-badge__badge {
    top: -4px;
    right: -2px;
  }
</style>
