<template>
  <v-dialog
    eager
    v-model="dialog"
    :width="width"
    transition="slide-x-transition"
    origin="center center"
    :scrollable="scrollable"
  >
    <v-card
      class="card-debugger-basedialog"
    >
      <v-card-title
        class="headline"
        primary-title
      >
        <v-row justify-space-between>
          <v-col class="title-card-debugger-text">
            <slot name="title">
              {{title}}
            </slot>
          </v-col>

          <v-col class="close-button-basedialog-debugger">
            <v-btn
              text
              icon
              @click="close()"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

      </v-card-title>

      <v-divider></v-divider>
      <div class="waitbar-debugger-basedialog" v-if="isLoaded === true">
        <WaitBar
          :message="message"
        >
        </WaitBar>
      </div>
      <v-card-text
        height="80%"
        v-else
        class="card-body-debugger"
        :style="noPadding ? 'padding: 0' : ''"
      >
        <slot name="content">

        </slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <slot name="footer">
          <v-btn
            color="primary"
            text
            @click="close()"
          >
            Close
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WaitBar from './WaitBar.vue'

export default {
  name: 'BaseCardDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    isLoaded: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    fullWidth: {
      type: Boolean,
      default: true
    },
    scrollable: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '100%'
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  },
  components: {
    WaitBar
  },
  data: function () {
    return {
      dialog: false
    }
  },
  created: function () {

  },
  mounted: function () {
    this.dialog = this.value
  },
  methods: {
    close: function () {
      this.dialog = false
    }
  },
  computed: {
  },
  watch: {
    value (val) {
      if (val !== this.dialog) {
        this.dialog = val
      }
    },
    async dialog (val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style>
  .card-body-debugger {
    background: #FAFAFA;
  }
  .card-debugger-basedialog .v-card__text  {
    padding: 2em;
  }

  .card-debugger-basedialog .group-key-info {
    margin-bottom: 2em;
  }

  .close-button-basedialog-debugger {
    text-align: right;
  }

  .data-table-basedialog-summary {
    margin-bottom: 2em;
  }

  .debug-table-info-div {
    padding: 1em;
    color: black;
    background: #f7f7f7;
    font-style: italic;
  }

  .debug-table-info-div span {
    padding: 1em;
  }

  .span-go-to {
    color: #1976d2;
    font-size: 15px;
    cursor: pointer;
  }

  .title-card-debugger-text {
    color: #1976d2;
    font-weight: bold;
  }

  .total-div-user {
    font-size: 20px;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .total-user-debugger {
    color: darkred;
    font-weight: bold;
  }

  h2.summary-title {
    margin-bottom: 1em;
    color: #1976d2;
  }

  .waitbar-debugger-basedialog .wait-bar-auth {
    margin-top: 0 !important;
  }

  .waitbar-debugger-basedialog {
    margin-top: 7em;
    margin-bottom: 7em;
  }
</style>
