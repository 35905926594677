<template>
  <v-row wrap class="justify-start">
    <v-col cols="6" v-if="users.length === 0">
      <v-avatar :color="$userHexColor('All')" size="24">
        <span class="avatar-letter white--text headline">All</span>
      </v-avatar>
    </v-col>
    <v-col cols="6" v-else v-for="(item, index) in computedUsers" v-bind:key="index">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-avatar v-on="on" :color="$userHexColor(item)" size="24">
            <span class="avatar-letter white--text headline">{{item[0].toUpperCase()}}</span>
          </v-avatar>
        </template>
        <span>{{typeof item === 'string' ? item : ''}}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'UsersInfo',
  props: {
    users: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {

  },
  data: function () {
    return {

    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {
    computedUsers: function () {
      let usersLength = this.users.length

      if (usersLength <= 4) {
        return this.users
      }

      let firstUser = this.users.slice(0, 3)
      let restUser = this.users.slice(3, usersLength)
      let moreUser = '+ ' + (usersLength - 3) + ' more (' + restUser.join(', ') + ')'

      return [...firstUser, moreUser]
    }
  },
  watch: {

  }
}
</script>

<style>
  .v-avatar span.avatar-letter {
    font-size: 12px !important;
    cursor: default;
  }
</style>
