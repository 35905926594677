<template>
  <div>
    <div v-if="!allData || !Object.keys(allData).length">
      <div class="label-very-strong">No data to display</div>
    </div>
    <div v-for="(item, key) in allData" v-bind:key="key">
      <div class="label-very-strong" :data-id="alert.id"> {{key}} : </div>
      <ul class="list-detailed-data">
        <li v-for="(item, index) in allData[key]" v-bind:key="index + '_' + key">
          <span v-if="item.label != null && item.label !== ''"><span :class="generateLabelClass(item)" v-html="computeLabel(item.label)"></span> :</span>
          <span :class="generateClass(item)" v-if="item.roundValue" v-html="getRoundedValue(item.value)"></span>
          <span :class="generateClass(item)" v-else-if="item.isPerc" v-html="getPercValue(item.value)"></span>
          <span :class="generateClass(item)" v-else-if="item.isDate" v-html="getDateValue(item.value)"></span>
          <span :class="generateClass(item)" v-else v-html="getNormalValue(item.value)"></span>
          {{ item.isComputedEndLabel ? $safeGet(alert, item.endLabel) : item.endLabel }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseAlertTemplate',
  props: {
    alert: {
      type: Object,
      default: function () {
        return {}
      }
    },
    settingsData: {
      type: Array,
      default: function () {
        return []
      }
    },
    detailedData: {
      type: Array,
      default: function () {
        return []
      }
    },
    algoStrategyData: {
      type: Array,
      default: function () {
        return []
      }
    },
    /*
    ** Use newDetailedData instead of combo [settingsData, detailedData, algoStrategyData] as a new way
    ** to give params to BaseAlertTemplate. See IntradayPacingOffTracks.vue for an example (1.3.1)
    */
    newDetailedData: {
      type: Object,
      default: function () {
        return null
      }
    }

  },
  components: {

  },
  data: function () {
    return {
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    getRoundedValue (value) {
      value = this.computeValue(value)
      return value !== undefined && value !== null ? this.$commonUtils.roundNum(value, 2) : 'NC'
    },
    getPercValue (value) {
      value = this.computeValue(value)
      return value !== undefined && value !== null ? this.$commonUtils.ratioToPerc(value) : 'NC'
    },
    getDateValue (value) {
      value = this.computeValue(value)
      return value !== undefined && value !== null ? this.$commonUtils.transformIsoDate(value) : 'NC'
    },
    getNormalValue (value) {
      value = this.computeValue(value)
      return value !== undefined && value !== null ? value : 'NC'
    },
    computeValue (value) {
      if (typeof value === 'function') {
        return value(this.alert)
      }
      const content = this.$safeGet(this.alert, value)
      return typeof content === 'string' ? this.$commonUtils.htmlEntities(content) : content
    },
    /**
     * compute label if needed
     * @param {string|function} label
     * @returns {String}
     */
    computeLabel (label) {
      return typeof label === 'function' ? label(this.alert) : label
    },
    generateLabelClass (item) {
      return { 'alert-colorize': item.colorize, 'label-strong': true }
    },
    generateClass (item) {
      return { 'alert-colorize': item.colorize, 'alert-bold': item.isBold }
    }
  },
  computed: {
    allData: function () {
      let finalAllData = {}

      const checkOptional = (item) => {
        return !item.optional || (this.computeValue(item.value) !== null && this.computeValue(item.value) !== 'NC')
      }

      const checkEmpty = (item) => {
        return item != null
      }

      if (this.newDetailedData != null) {
        Object.keys(this.newDetailedData).forEach(key => {
          let filteredData = this.newDetailedData[key].data.filter(checkEmpty).filter(checkOptional)
          if (filteredData.length > 0) {
            finalAllData = {
              ...finalAllData,
              ...{ [this.newDetailedData[key].title]: filteredData }
            }
          }
        })
      } else {
        const filterizedSettingsData = this.settingsData.filter(checkOptional)

        if (filterizedSettingsData.length > 0) {
          finalAllData = { ...finalAllData, ...{ 'Alerts settings': filterizedSettingsData } }
        }

        const filterizedDetailedData = this.detailedData.filter(checkOptional)

        if (filterizedDetailedData.length > 0) {
          finalAllData = { ...finalAllData, ...{ 'Detailed data': filterizedDetailedData } }
        }

        const filterizedAlgoStrategyData = this.algoStrategyData.filter(checkOptional)

        if (filterizedAlgoStrategyData.length > 0) {
          finalAllData = { ...finalAllData, ...{ 'IO algo strategy': filterizedAlgoStrategyData } }
        }
      }
      return finalAllData
    }
  },
  watch: {

  }
}
</script>

<style>
  .list-detailed-data {
    font-size: 8px;
    overflow-wrap: break-word;
  }

  .label-strong {
    font-weight: 500;
  }

  .label-very-strong {
    font-weight: bold;
  }

  .alert-colorize {
    color: #C51230;
    font-weight: bold;
  }

  .alert-bold {
    font-weight: 600;
  }
  /* Large screens ----------- */
  @media only screen
  and (min-width : 1824px) {
    .list-detailed-data {
      font-size: 10px;
      overflow-wrap: break-word;
    }
  }
</style>
