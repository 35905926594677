<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'
import { htmlEntitiesList } from '../../../../../../utils/commonUtils'

export default {
  name: 'IncoherenceInDatastoreSettingsV2',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        {
          label: 'Funnel level',
          value: (alert) => {
            if (alert.data.funnel === undefined || alert.data.funnel === null) {
              return `NC`
            }
            return `<br>-${alert.data.funnel.join('<br>-')}`
          },
          endLabel: '',
          roundValue: false,
          isPerc: false,
          colorize: false,
          isDate: false,
          optional: false
        },
        {
          label: 'Revenue level',
          value: (alert) => {
            if (alert.data.revenue_type === undefined || alert.data.revenue_type === null) {
              return `NC`
            }
            return `<br>-${this.$commonUtils.htmlEntitiesList(alert.data.revenue_type).join('<br>-')}`
          },
          endLabel: '',
          roundValue: false,
          isPerc: false,
          colorize: false,
          isDate: false,
          optional: true
        },
        {
          label: 'Business model',
          value: (alert) => {
            if (alert.data.business_model === undefined || alert.data.business_model === null) {
              return `NC`
            }
            return `<br>-${this.$commonUtils.htmlEntitiesList(alert.data.business_model).join('<br>-')}`
          },
          endLabel: '',
          roundValue: false,
          isPerc: false,
          colorize: false,
          isDate: false,
          optional: true
        },
        {
          label: 'Min viz',
          value: (alert) => {
            let dsp = alert.getDspName()
            let label = 'line item'
            let unit = '%'
            let endLabel = 'Viewability measurement rate'
            let multiplier = 100

            if (dsp === this.$MEDIAMATH) {
              label = 'strategy'
              unit = ''
              endLabel = ''
              multiplier = 1
            }

            let texts = []
            if (!alert.data.min_viz) {
              return null
            }
            let minViz = alert.data.min_viz[0]

            for (let key in minViz) {
              if (!minViz.hasOwnProperty(key)) {
                continue
              }
              texts.push(`${label} <strong>${this.$commonUtils.htmlEntities(key)}</strong> : <strong>${minViz[key] * multiplier} ${unit}</strong> ${endLabel}`)
            }

            return `<br>-${texts.join('<br>-')}`
          },
          endLabel: '',
          roundValue: false,
          isPerc: false,
          colorize: false,
          isDate: false,
          optional: true
        }
      ],
      settingsData: [
      ],
      algoStrategyData: [
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
