<template>
  <v-dialog
    eager
    v-model="dialog"
    width="500"
    transition="slide-x-transition"
    origin="center center"
    :persistent="true"
  >
    <v-card>
      <v-card-title
        class="headline grey lighten-2"
        primary-title
      >
        Info Status
      </v-card-title>

      <v-card-text>
       {{currentDataInfoStatus.type === 'true_positive'
        ? `Explain why this alert is not a false positive :`
        : `Explain why this alert need to be setted to ${currentDataInfoStatus.type} :`}}

        <v-checkbox
          v-if="currentDataInfoStatus.type === 'true_positive'"
          v-model="missClick"
          label="Click here if it is a missclick"
        >

        </v-checkbox>

        <v-textarea
          solo
          name="input-7-4"
          label="Info..."
          value=""
          v-model="textInfoStatus"
          id="textAreaInfoStatus"
          :disabled="missClick"
        ></v-textarea>

        <v-alert
          :value="showAlertInfoStatus"
          type="error"
        >
          You have to explain why this alert need to be setted to {{currentDataInfoStatus.type}}
        </v-alert>

        <v-autocomplete
          v-if="infoStatusIsAttributedOrFixed()"
          :label="currentDataInfoStatus.type === 'attributed' ? 'Attributed to : ' : 'Fixed by : '"
          v-model="selectUserInfoStatus"
          :items="getDebuggersName"
        >

        </v-autocomplete>

        <v-alert
          :value="showAlertUserInfoStatus"
          type="error"
        >
          Please enter a name
        </v-alert>

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="cancelInfoStatus()"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="closeInfoStatusDialog()"
          :disabled="textInfoStatus.trim() === '' && !missClick"
        >
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
// TODO use BaseCardDialog as Base for this component
export default {
  name: 'InfoStatusDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    currentDataInfoStatus: {
      type: Object,
      default: () => {
        return {
          type: null,
          alerts: null,
          isBucketsEdit: false
        }
      }
    }
  },
  components: {

  },
  data: function () {
    return {
      showAlertInfoStatus: false,
      showAlertUserInfoStatus: false,
      textInfoStatus: '',
      selectUserInfoStatus: '',
      dialog: false,
      missClick: false
    }
  },
  created: function () {

  },
  mounted: function () {
    this.dialog = this.value
  },
  methods: {
    setDefaultDebugger () {
      if (this.currentDataInfoStatus.type === 'fixed' && this.isUserDebugger) {
        this.selectUserInfoStatus = this.getCurrentUser
      }
    },
    closeInfoStatusDialog () {
      if (this.textInfoStatus.trim() === '' && (this.currentDataInfoStatus.type !== 'true_positive' || !this.missClick)) {
        this.showAlertInfoStatus = true
        return
      }

      this.showAlertInfoStatus = false

      if (this.selectUserInfoStatus.trim() === '' && ['fixed', 'attributed'].indexOf(this.currentDataInfoStatus.type) !== -1) {
        this.showAlertUserInfoStatus = true
        return
      }

      this.showAlertUserInfoStatus = false

      this.showAlertInfoStatus = false

      this.dialog = false

      if (this.currentDataInfoStatus.isBucketsEdit) {
        this.$emit('call-update-buckets',
          this.currentDataInfoStatus.type,
          this.textInfoStatus
        )
      } else {
        this.$emit('call-update',
          this.currentDataInfoStatus.type,
          this.currentDataInfoStatus.alerts,
          false,
          this.textInfoStatus,
          this.selectUserInfoStatus
        )
      }

      this.resetValue()
    },
    infoStatusIsAttributedOrFixed () {
      return ['attributed', 'fixed'].indexOf(this.currentDataInfoStatus.type) !== -1
    },
    resetValue () {
      this.textInfoStatus = ''
      this.selectUserInfoStatus = ''
      this.showAlertInfoStatus = false
      this.missClick = false
      this.setDefaultDebugger()
    },
    cancelInfoStatus () {
      this.dialog = false
      this.resetValue()
    },
    focusOnTextArea () {
      this.$nextTick(() => {
        let textArea = document.getElementById('textAreaInfoStatus')
        textArea.focus()
      })
    }
  },
  computed: {
    ...mapGetters(['getDebuggersName', 'getCurrentUser', 'isUserDebugger'])
  },
  watch: {
    value (val) {
      if (val !== this.dialog) {
        this.dialog = val
      }
    },
    dialog (val) {
      this.$emit('input', val)
      if (val) {
        this.focusOnTextArea()
        this.setDefaultDebugger()
      }
    },
    missClick (val) {
      if (val) {
        this.textInfoStatus = ''
      }
    }
  }
}
</script>

<style>
</style>
