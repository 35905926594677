<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate'

export default {
  name: 'ViewRateTooLowVsAcceptedThresholds',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        { label: 'Current viewability rate', value: 'data.current_viz', endLabel: '%', roundValue: false, isPerc: true, colorize: true, isBold: false },
        { label: 'Expected viewability rate', value: 'data.expected_viz', endLabel: '%', roundValue: false, isPerc: true, isBold: false },
        { label: 'Viewability measurement rate', value: 'data.imps_measured', endLabel: '%', roundValue: false, isPerc: true },
        { label: 'Estimated billing viewability rate', value: 'data.estimated_billing_viz', endLabel: '%', roundValue: false, isPerc: true },
        { label: 'Consecutive days out of bounds', value: 'data.Consecutive days out of bounds', endLabel: '', roundValue: false, isPerc: false },
        { label: 'Otto min viz status while alert was calculating', value: 'settings.opti_auto_minviz', endLabel: '', roundValue: false, isPerc: false }
      ],
      settingsData: [
        { label: 'Sensibility', value: 'data.day_sensibility', endLabel: '%', roundValue: false, isPerc: true },
        { label: 'Score', value: 'settings.limit_score', endLabel: '', roundValue: false, isPerc: false }
      ],
      algoStrategyData: [
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
