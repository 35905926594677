<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'

export default {
  name: 'MarginTooLowVs',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        { label: 'Current Margin', value: 'data.current_margin', endLabel: '%', roundValue: false, isPerc: true, colorize: true },
        { label: 'Lowest accepted margin', value: 'data.expected_margin', endLabel: '%', roundValue: false, isPerc: true },
        { label: 'Otto status while alert was calculating', value: 'settings.opti_auto', endLabel: '', roundValue: false, isPerc: false },
        { label: 'Estimated billing margin', value: 'data.estimated_billing_margin', endLabel: '%', roundValue: false, isPerc: true },
        { label: 'Consecutive days out of bounds', value: 'data.Consecutive days out of bounds', endLabel: '', roundValue: false, isPerc: false }
      ],
      settingsData: [
        { label: 'Sensibility', value: 'data.day_sensibility', endLabel: '%', roundValue: false, isPerc: true },
        { label: 'Score', value: 'settings.limit_score', endLabel: '', roundValue: false, isPerc: false }
      ],
      algoStrategyData: [
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
