<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'

export default {
  name: 'ABTestEnded',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        { label: 'baseline_id', value: 'data.baseline_id' },
        { label: 'overview_id', value: 'data.overview_id' },
        { label: 'control_io_id', value: 'data.control_io_id' },
        { label: 'start_date', value: 'data.start_date' },
        { label: 'end_date', value: 'data.end_date' }
      ],
      settingsData: [],
      algoStrategyData: []
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
