import { render, staticRenderFns } from "./Sidesheet.vue?vue&type=template&id=a3453536"
import script from "./Sidesheet.vue?vue&type=script&lang=js"
export * from "./Sidesheet.vue?vue&type=script&lang=js"
import style0 from "./Sidesheet.vue?vue&type=style&index=0&id=a3453536&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VAlert,VBtn,VDivider,VIcon,VProgressCircular,VSheet})
