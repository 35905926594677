<template>
  <BaseCardDialog
    v-model="dialog"
    :title="title"
  >
    <template v-slot:content>
      <v-textarea
        v-model="reason"
        label="Reason"
      >
      </v-textarea>
      <v-alert type="warning">
        When cancelling a alert relative to a brief, all instructions created in the surcouche for this insertion order will be deleted.
      </v-alert>
    </template>

    <template v-slot:footer>
      <v-btn
        color="primary"
        text
        :disabled="processing"
        @click="close()"
      >
        Close
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        :loading="processing"
        @click="confirm()"
      >
        Confirm
      </v-btn>
    </template>
  </BaseCardDialog>
</template>

<script>
import BaseCardDialog from '../../Common/BaseCardDialog'
import AlertModel from '@/models/Alertings/AlertModel'

export default {
  name: 'CancelAlertsDialog',
  components: {
    BaseCardDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    alert: {
      value: {
        type: AlertModel,
        default: false
      }
    }
  },
  data: function () {
    return {
      dialog: false,
      reason: '',
      processing: false
    }
  },
  methods: {
    close () {
      this.dialog = false
      this.reason = ''
    },
    async confirm () {
      this.processing = true
      let dsp = this.alert.group_key.dsp

      if (dsp === 'trueview') {
        dsp = this.$YOUTUBE
      }
      const groupKey = this.alert.keyFrom
      let overviewId = this.alert.data.overview_id
      let bulkAddId = 'bulk_add_id' in this.alert.data ? this.alert.data.bulk_add_id : null

      if (!overviewId && !bulkAddId) {
        overviewId = await this.getOverviewId(dsp)
        if (!overviewId) {
          const message = `Error when cancelling the alert. Can not find the overview_id. Please contact a administrator.`
          this.$emit('error', message)
          return
        }
      }

      const errorType = this.alert.error_type
      const reason = this.reason && typeof this.reason === 'string' ? this.reason.trim() : ''
      let response = null

      if (overviewId && !bulkAddId) {
        response = await this.$apiCaller.cancelAlertsBrief(groupKey, overviewId, errorType, reason, dsp)
      } else {
        response = await this.$apiCaller.cancelAlertsBulkAddBrief(groupKey, bulkAddId, errorType, reason, dsp)
      }

      if (this.$apiCaller.isResponseError(response)) {
        const error = this.$commonUtils.getErrorInResponse(response)
        let errorMessage = error && error.message ? error.message : error

        if (typeof errorMessage === 'object') {
          errorMessage = JSON.stringify(errorMessage)
        }

        const message = `Error when cancelling the alert. Response error : ${errorMessage}`
        this.$emit('error', message)
      } else {
        const message = 'Cancel success !'
        this.$emit('success', message, this.alert)
      }
      this.processing = false
      this.close()
    },
    async getOverviewId (dsp) {
      const ioId = this.alert.group_key.insertion_order_id
      const advertiserId = this.alert.group_key.advertiser_id
      const clientId = this.alert.group_key.client_id
      const response = await this.$apiCaller.getOverviewGroupKey(ioId, advertiserId, clientId, dsp)

      if (this.$apiCaller.isResponseError(response)) {
        const error = this.$commonUtils.getErrorInResponse(response)
        const message = `Error when cancelling the alert. Response error : ${error.message}`
        this.$emit('error', message)
      } else {
        if (response.data.length) {
          return response.data[0].id
        }
        return null
      }
    }
  },
  mounted: function () {
    this.dialog = this.value
  },
  computed: {
    title () {
      if (this.alert) {
        const ioId = this.alert.group_key.insertion_order_id
        const bulkAddId = 'bulk_add_id' in this.alert.data ? this.alert.data.bulk_add_id : null
        const dsp = this.alert.group_key.dsp.toUpperCase()
        const ioName = this.alert.group_key.io_name
        const errorType = this.alert.error_type
        return `Cancel ${bulkAddId ? 'Bulk Add ' : ''}alert ${errorType}. ${!bulkAddId ? ioName : ''} - ${dsp} (${ioId !== 'NONE' && !bulkAddId ? ioId : bulkAddId})`
      }
      return `Cancel alert.`
    }
  },
  watch: {
    value (val) {
      if (val !== this.dialog) {
        this.dialog = val
      }
    },
    async dialog (val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped>

</style>
