<template>
<BaseCardDialog
  v-model="dialog"
  title="Snooze Alert"
  :full-width="false"
  :width="'1200px'"
>
<template v-slot:content>
  <div>
    <v-form v-model="isFormValid">
      <v-text-field
        label="Snooze for"
        placeholder="1"
        class="shrink"
        suffix="hour(s)"
        v-model="time"
        :rules="[rules.required, rules.numeric, rules.noNegativeValue]"
      ></v-text-field>
    </v-form>
  </div>
</template>

  <template v-slot:footer>
    <v-spacer></v-spacer>
    <v-btn
      color="primary"
      text
      :disabled="processing"
      @click="close()"
    >
      Cancel
    </v-btn>
    <v-btn
      color="primary"
      text
      :disabled="valueError"
      :loading="processing"
      @click="confirm()"
    >
      Confirm
    </v-btn>
  </template>
</BaseCardDialog>
</template>

<script>
import BaseCardDialog from '@/components/Common/BaseCardDialog'
import AlertModel from '@/models/Alertings/AlertModel'
import { random } from 'lodash'
import { rulesMixin } from '@/mixins/rulesMixin'
export default {
  name: 'SnoozeAlertsDialog',
  components: { BaseCardDialog },
  mixins: [rulesMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    alert: {
      value: {
        type: AlertModel,
        default: false
      }
    }
  },
  data: function () {
    return {
      dialog: false,
      time: 0,
      isFormValid: false,
      processing: false
    }
  },
  computed: {
    valueError: function () {
      return !this.isFormValid
    }
  },
  methods: {
    close () {
      this.dialog = false
      this.time = 0
    },
    async confirm () {
      this.processing = true
      let dsp = this.alert.group_key.dsp

      if (dsp === 'trueview') {
        dsp = this.$YOUTUBE
      }
      const groupKey = this.alert.keyFrom
      const ioId = this.alert.group_key.insertion_order_id
      const errorType = this.alert.error_type
      const hours = this.time
      const response = await this.$apiCaller.snoozeAlert(groupKey, ioId, errorType, dsp, hours)

      if (this.$apiCaller.isResponseError(response)) {
        const error = this.$commonUtils.getErrorInResponse(response)
        console.log(error)
        const message = `Error when snoozing the alert. Response error : ${error}`
        this.$emit('error', message)
      } else {
        const message = `The alert(s) with the insertion order id ${ioId} will appear again in ${hours} hour(s)`
        this.$emit('success', message, this.alert)
      }
      this.processing = false
      this.close()
    }
  },
  mounted: function () {
    this.dialog = this.value
  },
  watch: {
    value (val) {
      if (val !== this.dialog) {
        this.dialog = val
      }
    },
    async dialog (val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped>
</style>
