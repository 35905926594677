<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'

export default {
  name: 'UnsupportedBusinessModels',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        { label: 'Nb active line items', value: 'settings.nb active line_items', endLabel: '', roundValue: false, isPerc: false, colorize: false },
        { label: 'Nb line items with unsupported business models', value: 'settings.nb not supported line_items', endLabel: '', roundValue: false, isPerc: false, colorize: false },
        { label: 'Mix of business models', value: 'data.mixed_business_models', endLabel: '', roundValue: false, isPerc: false, colorize: false }
      ],
      settingsData: [
      ],
      algoStrategyData: [
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
