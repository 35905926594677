<template>
  <BaseAlertTemplate
    :detailedData="returnRightDetailed()"
    :settingsData="settingsData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'

export default {
  name: 'CpmTooLowOrHight',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedDataMin: [
        { label: 'Current CPM', value: 'data.current_CPM', endLabel: 'data.currency', roundValue: true, isPerc: false, colorize: true, isComputedEndLabel: true },
        { label: 'Average CPM on past period', value: 'data.period_CPM', endLabel: 'data.currency', roundValue: false, isPerc: false, colorize: true, isComputedEndLabel: true },
        { label: 'Lowest accepted min CPM', value: 'data.expected_min_CPM', endLabel: 'data.currency', roundValue: true, isPerc: false, colorize: false, isComputedEndLabel: true },
        { label: 'Otto status while alert was calculating', value: 'settings.opti_auto', endLabel: '', roundValue: false, isPerc: false, colorize: false },
        { label: 'Estimated billing CPM ', value: 'data.estimated_billing_CPM', endLabel: 'data.currency', roundValue: false, isPerc: false, colorize: false, isComputedEndLabel: true },
        { label: 'Consecutive days out of bounds', value: 'data.Consecutive days out of bounds', endLabel: '', roundValue: false, isPerc: false, colorize: false }
      ],
      detailedDataMax: [
        { label: 'Current CPM', value: 'data.current_CPM', endLabel: 'data.currency', roundValue: true, isPerc: false, colorize: true, isComputedEndLabel: true },
        { label: 'Average CPM on past period', value: 'data.period_CPM', endLabel: 'data.currency', roundValue: false, isPerc: false, colorize: true, isComputedEndLabel: true },
        { label: 'Highest accepted max CPM', value: 'data.expected_max_CPM', endLabel: 'data.currency', roundValue: true, isPerc: false, colorize: false, isComputedEndLabel: true },
        { label: 'Otto status while alert was calculating', value: 'settings.opti_auto', endLabel: '', roundValue: false, isPerc: false, colorize: false },
        { label: 'Estimated billing CPM ', value: 'data.estimated_billing_CPM', endLabel: 'data.currency', roundValue: false, isPerc: false, colorize: false, isComputedEndLabel: true },
        { label: 'Consecutive days out of bounds', value: 'data.Consecutive days out of bounds', endLabel: '', roundValue: false, isPerc: false, colorize: false }
      ],
      settingsData: [
        { label: 'Sensibility', value: 'data.day_sensibility', endLabel: '%', roundValue: false, isPerc: true },
        { label: 'Score', value: 'settings.limit_score', endLabel: '', roundValue: false, isPerc: false }
      ],
      algoStrategyData: [
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    returnRightDetailed () {
      return (this.$safeGet(this.alert, 'data.expected_min_CPM')) !== undefined ? this.detailedDataMin : this.detailedDataMax
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
