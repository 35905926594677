import Vue from 'vue'
import Component from 'vue-class-component'
import * as rules from '@/rules/rules'

interface RulesRulesMixin {
  required: rules.RuleType
  notEmpty: rules.RuleType
  requiredAllowZero: rules.RuleType
  numeric: rules.RuleType
  numericAllowUndef: rules.RuleType
  noNegativeValue: rules.RuleType
  counter: rules.RuleType[],
  percentage: rules.RuleType
}

@Component
export class rulesMixin extends Vue {
  rules: RulesRulesMixin = {
    // required: v => !!v || 'This field must be completed',
    required: rules.required,
    notEmpty: rules.notEmpty,
    requiredAllowZero: rules.requiredAllowZero,
    numeric: rules.numeric,
    numericAllowUndef: rules.numericAllowUndef,
    noNegativeValue: rules.noNegativeValue,
    counter: rules.counter,
    percentage: rules.percentage
  }
  digitMask: Array<string> = []

  created () {
    this.digitMask = this.$commonUtils.generateDigitMask()
  }

  betweenZeroAndHundred (computedAttribut: KeysOf<ThisType<any>>) {
    return this.betweenRule(computedAttribut, 0, 100)
  }

  betweenRule (attribut: KeysOf<ThisType<any>>,
    min: number,
    max: number,
    customMessage: string = null,
    excludedMin: boolean = false,
    excludedMax: boolean = false) {
    return this.isBetweenRule(this[attribut], min, max, customMessage, excludedMin, excludedMax)
  }

  isBetweenZeroAndHundred (value: number) {
    return this.isBetweenRule(value, 0, 100)
  }

  minimumValue (value: number, min: number, excluded: boolean = false) {
    return rules.minimumValue(value, min, excluded)
  }

  maximumValue (value: number, max: number, excluded: boolean = false) {
    return rules.maximumValue(value, max, excluded)
  }

  isBetweenRule (value: number, min: number, max: number, customMessage: string = null, excludedMin: boolean = false, excludedMax: boolean = false) {
    let ruleMin = value < min
    let ruleMax = value > max

    if (excludedMin) {
      ruleMin = value <= min
    }

    if (excludedMax) {
      ruleMax = value >= max
    }

    if (ruleMin || ruleMax) {
      if (customMessage !== null) {
        return customMessage
      }
      return `This value must be between ${min} and ${max}`
    }
  }

  /**
   * compare 2 value of a form, return error message if the highest is not greater than the lowest
   * the error message is generated by taking the label
   * @param highest
   * @param lowest
   * @param labelHighest
   * @param labelLowest
   * @returns {[]}
   */
  mustBeGreaterRules (highest: any, lowest: any, labelHighest: string, labelLowest: string) {
    let errors = []

    if (!isNaN(highest) && !isNaN(lowest) &&
      this.$commonUtils.isSetAndNotEmpty(highest) && this.$commonUtils.isSetAndNotEmpty(lowest)) {
      if (Number(highest) < Number(lowest)) {
        errors.push(`${this.$commonUtils.capitalize(labelHighest)} must be greater than ${labelLowest}.`)
      }
    }
    return errors
  }

  mustBeAsciiChars (valueToTest: string) {
    let errors = []
    if (!this.testAsciiChars(valueToTest)) {
      errors.push('Must contain only ASCII characters. (Cannot contain accents)')
    }
    return errors
  }

  /**
   * test if a value is ASCII (not extended)
   * https://stackoverflow.com/a/18017956/11089121
   * @param valueToTest
   * @return {boolean}
   */
  testAsciiChars (valueToTest: string) {
    // eslint-disable-next-line no-control-regex
    let asciiRegex = /^[\x00-\x7F]+$/
    return asciiRegex.test(valueToTest)
  }

  errorMessageValueCantBeEmpty (value: any, label: string) {
    let errors = []
    if (!value) {
      errors.push(`The field "${label}" must be filled !`)
    }

    return errors
  }

  tempErrorMessageIntegrity (name: any, nameList: string[]) {
    let errorMessage = []
    if (typeof name === 'string' && nameList.includes(name.trim())) {
      errorMessage.push('This name already exist.')
    }
    return errorMessage
  }
}
