<template>
  <BaseAlertTemplate
    :alert="alert"
    :newDetailedData="newDetailedData"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'

export default {
  name: 'IntradayPacingOffTracks',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      newDetailedData: {
        pacingInsights: {
          title: 'Pacing Insights',
          data: [
            this.getLineByFunction(this.getPacingInsightsSpendVsObjLine, this.alert),
            this.getLineByFunction(this.getPacingInsightsSpendPctHrVsExpectedLine, this.alert)
          ]
        },
        ioConstraints: {
          title: 'IO Constraints',
          data: [
            this.getLineByFunction(this.getIoConstraintsLimitingMaxALine, this.alert),
            this.getLineByFunction(this.getIoConstraintsLimitingMaxBidLine, this.alert),
            this.getLineByFunction(this.getIoConstraintsMaxFreqLine, this.alert)
          ]
        },
        algoSettings: {
          title: 'Algo Settings',
          data: [
            this.getLineByFunction(this.getAlgoSettingsOptiOttoLine, this.alert),
            this.getLineByFunction(this.getAlgoSettingsOttoMaxBidLine, this.alert),
            this.getLineByFunction(this.getAlgoSettingsOttoMinVizLine, this.alert),
            this.getLineByFunction(this.getAlgoSettingsUnderdeliveryCustomLine, this.alert),
            this.getLineByFunction(this.getAlgoSettingsOverdeliveryCustomLine, this.alert)
          ]
        }
      }
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    isDataAvailable (alert) {
      return alert.data != null && alert.data.no_respect != null && alert.data.no_respect.length > 0
    },
    getLineByFunction (fn, alert) {
      if (!this.isDataAvailable(alert)) {
        return null
      }
      return fn(alert)
    },
    // PACING INSIGHTS FUNCTIONS
    getPacingInsightsSpendVsObjLine (alert) {
      return {
        value: (alert) => {
          return `The campaign spent <strong class="darkred">${alert.data.no_respect[0].spend}</strong> vs 
          <strong class="darkred">${alert.data.no_respect[0].obj}</strong> at <strong class="darkred">${alert.settings.hour_check}</strong>`
        }
      }
    },
    getPacingInsightsSpendPctHrVsExpectedLine (alert) {
      return {
        value: (alert) => {
          return `The campaign spent <strong class="darkred">${alert.data.no_respect[0].pct_spent_hour}</strong> vs \
          <strong class="darkred">${alert.data.no_respect[0].expected_pct_spend_hour}</strong> at \
          <strong class="darkred">${alert.settings.hour_check}</strong>`
        }
      }
    },
    // IO CONSTRAINTS FUNCTIONS
    getIoConstraintsLimitingMaxALine (alert) {
      if (alert.data.no_respect[0].limiting_max_A != null && parseFloat(alert.data.no_respect[0].limiting_max_A) > 0) {
        return {
          label: 'Limiting Max A',
          value: (alert) => {
            return alert.data.no_respect[0].limiting_max_A
          }
        }
      }
      return null
    },
    getIoConstraintsLimitingMaxBidLine (alert) {
      if (alert.data.no_respect[0].limiting_max_bid != null && parseFloat(alert.data.no_respect[0].limiting_max_bid) > 0) {
        return {
          label: 'Limiting Max Bid',
          value: (alert) => {
            return alert.data.no_respect[0].limiting_max_bid
          }
        }
      }
      return null
    },
    getIoConstraintsMaxFreqLine (alert) {
      if (alert.data.no_respect[0].is_max_fq_reached === 'true') {
        return {
          label: 'Max frequency reached',
          value: (alert) => {
            return alert.data.no_respect[0].is_max_fq_reached
          }
        }
      }
      return null
    },
    // ALGO SETTINGS FUNCTIONS
    getAlgoSettingsOptiOttoLine (alert) {
      return {
        label: 'Otto status while alert was calculating',
        value: (alert) => {
          return `${alert.settings.opti_auto === true ? 'true' : '<strong class="darkred">false</strong>'}`
        }
      }
    },
    getAlgoSettingsOttoMaxBidLine (alert) {
      if (alert.settings.manual_max_A == null) {
        return {
          value: (alert) => {
            return 'Otto max bid activated'
          }
        }
      }
      return null
    },
    getAlgoSettingsOttoMinVizLine (alert) {
      if (alert.settings.otto_min_viz === true) {
        return {
          label: 'Otto min viz activated',
          value: (alert) => {
            return alert.settings.otto_min_viz
          }
        }
      }
      return null
    },
    getAlgoSettingsUnderdeliveryCustomLine (alert) {
      if (alert.settings.underdelivery_custom === true) {
        return {
          label: 'Underdelivery Custom',
          value: (alert) => {
            return alert.settings.underdelivery_custom
          }
        }
      }
      return null
    },
    getAlgoSettingsOverdeliveryCustomLine (alert) {
      if (alert.settings.overdelivery_custom === true) {
        return {
          label: 'Overdelivry Custom',
          value: (alert) => {
            return alert.settings.overdelivery_custom
          }
        }
      }
      return null
    },
    forceColorizeMaxA (alert) {
      return parseFloat(alert.data.limiting_max_A) > 85
    },
    forceColorizeMaxBid (alert) {
      return parseFloat(alert.data.limiting_max_bid) > 85
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
.darkred {
  color: darkred;
}
</style>
