import { $APPNEXUS, $BEESWAX, $DBM, $MEDIAMATH, $THETRADEDESK, $YOUTUBE } from '../../../../../config/dspConfig'

const SpecificHeaderNamesByDsp: { [key: string]: { [key: string]: string } } = {
  [$APPNEXUS]: { 'member': 'Member', 'io': 'Insertion Order ID' },
  [$MEDIAMATH]: { 'member': 'Organization', 'io': 'Campaign ID' },
  [$THETRADEDESK]: { 'member': 'Partner', 'io': 'Campaign ID' },
  [$DBM]: { 'member': 'Partner', 'io': 'Insertion Order ID' },
  [$YOUTUBE]: { 'member': 'Partner', 'io': 'Insertion Order ID' },
  [$BEESWAX]: { 'member': 'Buzz Key', 'io': 'Insertion Order ID' }
}

function getBaseHeadersForGivenDsp (dsp: string): Array<Array<{ [key: string]: string }>> {
  return [
    [
      { label: null, value: null, img: null, icon: null, class: 'checkbox-col' },
      { label: null, value: null, img: null, icon: null, class: 'color-col' },
      { label: 'AM : ', value: 'AM', img: null, icon: null, class: 'am-col' },
      { label: null, value: null, img: 'group_key.dsp', icon: null, class: 'dsp-col' }
    ],
    [
      { label: 'Advertiser', value: 'group_key.advertiser_id', img: null, icon: null, class: 'ad-col' }
    ]
  ]
}

function getBriefHeadersForGivenDsp (dsp: string): Array<{ [key: string]: string }> {
  return [
    ...getBaseHeadersForGivenDsp(dsp)[0],
    { label: SpecificHeaderNamesByDsp[dsp]['member'], value: 'group_key.client_id', img: null, icon: null, class: 'member-col' },
    ...getBaseHeadersForGivenDsp(dsp)[1],
    { label: SpecificHeaderNamesByDsp[dsp]['io'], value: 'group_key.insertion_order_id', img: null, icon: null, class: 'io-col' }
  ]
}

function getBulkAddHeadersForGivenDsp (dsp: string): { [key: string]: any } {
  return [
    ...getBaseHeadersForGivenDsp(dsp)[0],
    { label: SpecificHeaderNamesByDsp[dsp]['member'], value: 'group_key.client_id', img: null, icon: null, class: 'member-col' },
    ...getBaseHeadersForGivenDsp(dsp)[1],
    { label: `Create a unique surcouche brief for these Bulk Add IOs`, value: '', img: null, icon: null, class: 'io-col' }
  ]
}

export function getAlertsTableHeaders () {
  let base: { [key: string]: { [key: string]: Object } } = { 'brief': {}, 'bulkadd': {} }
  Object.keys(SpecificHeaderNamesByDsp).forEach((dsp: string) => {
    base['brief'][dsp] = { ...getBriefHeadersForGivenDsp(dsp) }
    base['bulkadd'][dsp] = { ...getBulkAddHeadersForGivenDsp(dsp) }
  })
  return base
}
