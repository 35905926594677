<template>
  <div class="group-key-info" v-if="displayGroupKeyInfo(groupKey)">
    <div>
      <span>
        <span class="group-key-label">DSP</span> : <span class="group-key-value"> {{groupKey.dsp}} </span>
      </span>
      <v-tooltip top :disabled="!groupKey.member_name">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <span class="group-key-label">
              {{clientValue.replace(/_/g, ' ').ucwords()}}
            </span> : <span class="group-key-value">
              &nbsp;{{groupKey.client_id != 'NONE' ? groupKey.client_id : 'Multiple values'}}
            </span>
          </span>
        </template>
        <span>
          {{groupKey.member_name ? (groupKey.member_name != 'NONE' ? groupKey.member_name : 'Multiple values') : ''}}
        </span>
      </v-tooltip>
    </div>

    <div>
      <span>
        <span class="group-key-label">Advertiser Id</span> : <span class="group-key-value">
          &nbsp;{{groupKey.advertiser_id != 'NONE' ? groupKey.advertiser_id : 'Multiple values'}}
        </span>
      </span>
      <v-tooltip top :disabled="!groupKey.io_name">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <span class="group-key-label">{{ioField.replace(/_/g, ' ').ucwords()}}</span> :
            <span class="group-key-value">
              &nbsp;{{groupKey.insertion_order_id != 'NONE' ? groupKey.insertion_order_id : 'Multiple values'}}
            </span>
          </span>
        </template>
        <span>
          {{groupKey.io_name ? groupKey.io_name : ''}}
        </span>
      </v-tooltip>

    </div>
    <div v-if="errorType" :style="`color : ${infoError.color}`">
      <span>
        {{errorType}} {{infoError.name}}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupKeyInfo',
  props: ['groupKey', 'errorType', 'dsp'],
  components: {

  },
  data: function () {
    return {
      infoError: {
        color: '',
        name: ''
      }
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    displayGroupKeyInfo (groupKey) {
      return typeof groupKey === 'object' && !this.$commonUtils.objectIsEmpty(groupKey)
    }
  },
  computed: {
    clientValue: function () {
      return this.$getClientValue(this.dsp)
    },
    ioField: function () {
      return this.$getIoField(this.dsp, true)
    }
  },
  watch: {
    errorType (value) {
      let info = this.$getInfoError(value)
      this.infoError = {
        color: info.color,
        name: info.name
      }
    }
  }
}
</script>

<style>
  .group-key-info {
  font-style: italic;
  }

  .group-key-label {
    font-weight: 500;
  }
</style>
