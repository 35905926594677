<template>
        <v-sheet
          color="white lighten-3"
          height="max-content"
          :width="isOpen ? width.open : width.large"
          min-height="100%"
        >

          <div
          class="header-sheet"
          >
            <v-btn text icon @click="isOpen = !isOpen">
                <v-icon>{{isOpen ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}}</v-icon>
            </v-btn>
          </div>
          <v-divider></v-divider>
          <div
            class="overflow-content-sheet"
          >
            <div
            class="content-sheet"
            v-show="isOpen"
            >
              <div v-if="noAlertSelected">
                {{message.noAlertSelected}}
              </div>

              <GroupKeyInfo
                :groupKey="selectedAlert[0] !== undefined && selectedAlert[0].group_key !== undefined ? selectedAlert[0].group_key : {}"
                :errorType="selectedAlert[0] !== undefined && selectedAlert[0].error_type !== undefined ? selectedAlert[0].error_type : ''"
                :dsp="selectedAlert[0] !== undefined && selectedAlert[0].getDspName() !== undefined ? selectedAlert[0].getDspName() : ''"
              >

              </GroupKeyInfo>

              <v-divider></v-divider>

              <div v-if="isLoadingSelected" style="text-align: center" class="pa-12">
                <v-progress-circular
                  color="primary"
                  indeterminate
                >
                </v-progress-circular>
              </div>

              <div v-if="!isLoadingSelected">
                <div v-for="(item, index) in selectedData" v-bind:key="index">
                  <AlertSideData
                    class="content-visibility-auto"
                    :alert="item"
                    :notes="notes"
                    :is-expired="false"
                    :check-pre-mep-result="checkPreMepResult"
                  >
                  </AlertSideData>
                  <v-divider></v-divider>
                </div>
              </div>

              <div v-if="!noAlertSelected">

                <div v-if="isLoading">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>

                <div v-else>
                  <div v-if="loadedData.length === 0 && noData === false" class="div-button-load">
                    <v-btn
                      color="primary"
                      @click="loadMore()"
                      text
                      small
                    >
                      Load more
                    </v-btn>
                  </div>
                  <div v-else v-for="(item, index) in loadedData" v-bind:key="index">
                    <AlertSideData
                      class="content-visibility-auto"
                      :alert="item"
                      :isExpired="true"
                      :notes="notes"
                      :check-pre-mep-result="checkPreMepResult"
                    >
                    </AlertSideData>
                    <v-divider></v-divider>
                  </div>
                </div>

                <div v-if="noData">
                  <v-alert type="info" outlined class="alert-no-data">
                    No more data for this error type in this group key
                  </v-alert>
                </div>
              </div>
            </div>
          </div>
        </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { GroupKeyInfo, AlertSideData } from './SheetFragments'

export default {
  name: 'Sidesheet',
  props: ['selectedAlert'],
  components: {
    GroupKeyInfo,
    AlertSideData
  },
  data: function () {
    return {
      isOpen: false,
      noAlertSelected: true,
      message: {
        noAlertSelected: 'Click on a alert to display her information here.'
      },
      width: {
        open: '480',
        close: '50'
      },
      loadedData: [],
      selectedData: [],
      noData: false,
      isLoading: false,
      isLoadingSelected: false,
      noDataSelected: false,
      notes: [],
      /**
       * Check pre mep data
       * @type {CheckPreMepResult | null}
       */
      checkPreMepResult: null
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    ...mapActions(['getAlertsOfGroupKeyData']),
    async _getAlerts (expired) {
      let groupKeyId = this.selectedAlert[0].keyFrom
      let loadingKey = expired ? 'isLoading' : 'isLoadingSelected'
      let noDataKey = expired ? 'noData' : 'noDataSelected'
      let commitLoaded = expired ? 'setLoadedData' : 'setSelectedData'
      let getLoaded = expired ? 'getLoadedData' : 'getSelectedData'
      let alertKey = expired ? 'loadedData' : 'selectedData'
      this[loadingKey] = true

      let response = await this.getAlertsOfGroupKeyData({ groupKeyId: groupKeyId, expired: expired })
      if (this.$apiCaller.isApiResponseStatusUnauthorized(response)) {
        this.$store.commit('setAskRelog', true)
      }

      this.$store.commit(commitLoaded, response.data)

      let loadedData = this[getLoaded]

      if (loadedData[groupKeyId] !== undefined) {
        let alerts = loadedData[groupKeyId]
        if (alerts !== undefined && alerts.length) {
          this[alertKey] = alerts
        } else {
          this[noDataKey] = true
          console.log('No' + (expired ? 'expired' : '') + ' alerts for this error type in this group of data')
        }
      } else {
        this[noDataKey] = true
        console.log('No' + (expired ? 'expired' : '') + ' alerts for this group of data')
      }

      this[loadingKey] = false
    },
    async getSelectedAlerts () {
      await this._getAlerts(false)
    },
    async loadMore () {
      await this._getAlerts(true)
    },
    resetValue () {
      this.selectedData = []
      this.loadedData = []
      this.noData = false
      this.noDataSelected = false
      this.isLoading = false
      this.isLoadingSelected = false
      this.checkPreMepResult = null
    },
    async getNotes () {
      let ioId = this.selectedAlert[0].group_key.insertion_order_id
      let dspId = this.selectedAlert[0].group_key.dsp
      const response = await this.$apiCaller.getInstructionNotes(ioId, dspId)
      if (response.data && !response.data.isEmpty) {
        this.notes = response.data
      }
    },
    needCheckPreMepData (errorType) {
      return ['4.1.1', '4.2.1'].includes(errorType)
    },
    /**
     * Load check pre mep data
     * @param {AlertModel} alert
     */
    async loadCheckPreMepData (alert) {
      this.checkPreMepResult = null
      const response = await this.$store.dispatch('getCheckPreMepDataOfAlerts', alert)

      if (!this.$apiCaller.isResponseError(response)) {
        this.checkPreMepResult = response.data
      } else {
        console.warn('Error when calling api getCheckPreMepDataOfAlerts')
        this.checkPreMepResult = null
      }
    }
  },
  computed: {
    ...mapGetters(['getLoadedData', 'getSelectedData'])
  },
  watch: {
    selectedAlert: {
      deep: true,
      handler: function () {
        if (this.selectedAlert === undefined || this.$commonUtils.objectIsEmpty(this.selectedAlert)) {
          this.noAlertSelected = true
        } else {
          this.noAlertSelected = false
          if (this.isOpen === false) {
            this.isOpen = true
          }
        }

        this.resetValue()

        if (this.selectedAlert && this.selectedAlert.length) {
          this.getSelectedAlerts()
          this.getNotes()
          const alert = this.selectedAlert[0]
          if (this.needCheckPreMepData(alert.error_type)) {
            this.loadCheckPreMepData(alert)
          } else {
            this.checkPreMepResult = null
          }
        }
      }
    }
  }
}
</script>

<style>
.header-sheet {
  width: 100%;
  height: 3em;
}

.content-sheet {
  font-size: 10px;
  text-align: left;
  padding: 1em;
}

.alert-id-div {
  text-align: center;
  font-weight: 700;
  color: slateblue;
}
.overflow-content-sheet {
  height: 70vh;
  overflow: auto;
}

.alert-no-data {
  font-size: 10px;
  padding: 6px;
  margin: 26px;
}

.div-button-load {
  text-align: right;
}
/* Large screens ----------- */
@media only screen
and (min-width : 1824px) {
  .overflow-content-sheet {
    height: 62em;
  }

  .content-sheet {
    font-size: 12px;
  }
}

</style>
