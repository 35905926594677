<template>
  <BaseAlertTemplate
   :detailedData="detailedData"
   :settingsData="settingsData"
   :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'

export default {
  name: 'AlertingsMargin',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        { label: 'Measured hour', value: 'data.hour', endLabel: '', roundValue: false, isPerc: false },
        { label: 'Profit including fees and third party cost', value: 'data.real_profit', endLabel: 'USD', roundValue: true, isPerc: true, isBold: true },
        { label: 'Profit including fees only', value: 'data.profit_including_fees', endLabel: 'USD', roundValue: true, isPerc: false },
        { label: 'Revenue', value: 'data.revenue', endLabel: 'USD', roundValue: true, isPerc: false },
        { label: 'Media Cost', value: 'data.cost', endLabel: 'USD', roundValue: true, isPerc: false },
        { label: 'Third party costs', value: 'data.third_party_costs', endLabel: 'USD', roundValue: true, isPerc: false }
      ],
      settingsData: [
        { label: 'Real profit Threshold', value: 'settings.profit_threshold', endLabel: 'USD', roundValue: true, isPerc: false },
        { label: 'Look-up period', value: 'settings.lookup_period', endLabel: '', roundValue: false, isPerc: false }
      ],
      algoStrategyData: [
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
