<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'

export default {
  name: 'UnderdeliveryEndOfBilling',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        {
          label: 'Alert level',
          value: (alert) => {
            return alert.data.concerned_lvl !== undefined
              ? this.$commonUtils.htmlEntities(alert.data.concerned_lvl)
              : this.$commonUtils.htmlEntities(alert.data.no_respect[0].lvl)
          },
          endLabel: '',
          roundValue: false,
          isPerc: false,
          colorize: false,
          isDate: false
        },
        {
          label: 'Consecutive days worse case',
          value: 'data.max_nb_days',
          endLabel: '',
          roundValue: false,
          isPerc: false,
          colorize: false,
          isDate: false
        },
        { label: 'Underdelivery D-1 worse case', value: 'data.max_underdelivery', endLabel: '', roundValue: false, isPerc: false, colorize: false, isDate: false }
      ],
      settingsData: [
        { label: 'Limit score', value: 'settings.limit_score', endLabel: '', roundValue: false, isPerc: false, colorize: false },
        { label: 'Underdelivery Custom', value: 'settings.underdelivery_custom', endLabel: '', roundValue: false, isPerc: false, colorize: false }
      ],
      algoStrategyData: [
        { label: 'Limiting max A', value: 'data.limiting_max_A', endLabel: '', roundValue: false, isPerc: false, colorize: this.forceColorizeMaxA(this.alert) },
        { label: 'Limiting max bid', value: 'data.limiting_max_bid', endLabel: '', roundValue: false, isPerc: false, colorize: this.forceColorizeMaxBid(this.alert) },
        { label: 'Max frequency reached', value: 'data.max_freq_reached', endLabel: '', roundValue: false, isPerc: false, colorize: false },
        { label: 'Otto status while alert was calculating', value: 'settings.opti_auto', endLabel: '', roundValue: false, isPerc: false, colorize: false },
        { label: 'History of Otto status', value: 'data.otto_status_since', endLabel: '', roundValue: false, isPerc: false, colorize: false, isDate: false }
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    forceColorizeMaxA (alert) {
      return parseFloat(alert.data.limiting_max_A) > 85
    },
    forceColorizeMaxBid (alert) {
      return parseFloat(alert.data.limiting_max_bid) > 85
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
