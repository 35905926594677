<template>
<BaseCardDialog
  v-model="dialog"
  title="Notes"
  :full-width="false"
  :width="'1200px'"
  :no-padding="true">

  <template v-slot:content>
        <v-row no-gutters wrap>
          <v-col cols="12" v-for="(note, index) in notesByDate" v-bind:key="index" class="py-2">
            <v-row no-gutters wrap>
              <v-col class="pa-2" cols="10">
                <strong>{{note.surcouche_user_mail}}</strong> said on {{displayDate(note.insertion_date)}}
              </v-col>
            </v-row>
            <v-textarea
              class="content"
              :value="note.content"
              readonly
              outlined
              hide-details
            >
            </v-textarea>
          </v-col>
        </v-row>
  </template>

</BaseCardDialog>
</template>

<script>
import BaseCardDialog from '@/components/Common/BaseCardDialog'
export default {
  name: 'IONotesDialog',
  props: {
    notes: {
      type: Array,
      default: function () {
        return []
      }
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  components: { BaseCardDialog },
  data: function () {
    return {
      dialog: false
    }
  },
  watch: {
    value (val) {
      if (val !== this.dialog) {
        this.dialog = val
      }
    },
    dialog (val) {
      this.$emit('input', val)
    }
  },
  computed: {
    notesByDate: function () {
      let tmp = [...this.notes]
      return tmp.sort(function (a, b) {
        return new Date(b.insertion_date).getTime() - new Date(a.insertion_date).getTime()
      })
    }
  },
  methods: {
    displayDate (date) {
      if (!date) {
        return null
      }
      let toDate = new Date(date)
      return toDate.toISOString().split('T')[0] + ' ' + toDate.toLocaleTimeString()
    }
  }
}
</script>

<style scoped>
.content {
  padding-left: 2%;
  padding-right: 2%;
}
</style>
