
import Vue from 'vue'
import Component from 'vue-class-component'
import SimpleDialog from '@/components/TableComponents/Form/FormFragments/SimpleDialog.vue'
import BaseCardDialog from '@/components/Common/BaseCardDialog.vue'

@Component({
  components: {
    SimpleDialog,
    BaseCardDialog
  }
})
export default class AlertExpandFilter extends Vue {
  baselineType: BaselineType | 'N/A' = 'N/A'
  isStrat: boolean | 'N/A' = 'N/A'
  alertCategory: Array<string[]> = []
  selectedStratLvl: StratLvl[] | null = null

  stratLvlItem: TextValueType<StratLvl[]>[] = [
    {
      text: 'Standard & above (default)',
      value: ['standard', 'under_monitoring']
    },
    {
      text: 'Under monitoring',
      value: ['under_monitoring']
    }
  ]

  itemErrorFilter: TextValueType<string[]>[] = [
    {
      text: 'Brief',
      value: ['4.1.1', '4.1.2', '4.1.3', '4.2.1', '4.2.4', '4.2.5', '4.3.1']
    },
    {
      text: 'Autoplugs',
      value: ['4.2.1', '4.2.2', '4.2.3', '4.2.4', '4.2.5', '4.2.6']
    },
    {
      text: 'Bulk Add Brief',
      value: ['4.3.1']
    },
    {
      text: 'Constraints',
      value: ['2.2.2', '2.2.1']
    },
    {
      text: 'Intraday pacing',
      value: ['1.3.1']
    },
    {
      text: 'IO settings',
      value: ['1.2.1', '1.2.2', '2.2.5', '2.3.1', '2.3.2', '2.3.3', '2.5.1']
    },
    {
      text: 'Margin management',
      value: ['2.2.3', '2.4.1']
    },
    {
      text: 'Negative Margin',
      value: ['1.1.1', '1.1.2', '1.1.3']
    },
    {
      text: 'Overpacing - Daily',
      value: ['2.1.1']
    },
    {
      text: 'Underdelivery - Daily',
      value: ['2.1.3']
    },
    {
      text: 'Underdelivery end of billing',
      value: ['1.3.2']
    },
    {
      text: 'Ab Test',
      value: ['5.1.1']
    }
  ]

  optiAutoFilter: boolean = null

  nbHoursBeforePopUp = 1
  timerId: number = null

  dialogRefreshFilter = false

  emitApplyFilter () {
    this.$emit('baselineFilterUpdated', this.baselineType)
  }

  emitApplyFilterOptiAuto () {
    this.$emit('optiAutoFilterUpdated', this.optiAutoFilter)
  }

  emitApplyFilterStratLvl () {
    this.$emit('stratLvlUpdated', this.selectedStratLvl)
  }

  emitApplyAlertCategory () {
    if (this.alertCategory.length) {
      this.launchFilterTimer()
    } else {
      this.removeTimerFilter()
    }
    let errorTypeListCategory = this.alertCategory.reduce((pre, curr) => {
      return [...pre, ...curr]
    }, [])
    this.$emit('alertCategoryUpdated', errorTypeListCategory)
  }

  closeDialogRefreshFilter () {
    if (this.alertCategory.length) {
      this.launchFilterTimer()
    }
    this.dialogRefreshFilter = false
  }

  resetFilterErrorTypeCategory () {
    this.alertCategory = []
    this.emitApplyAlertCategory()
    this.closeDialogRefreshFilter()
  }

  removeTimerFilter () {
    if (this.timerId) {
      clearTimeout(this.timerId)
    }
  }

  toggle () {
    this.$nextTick(() => {
      if (this.allAlertsSelected) {
        this.alertCategory = []
      } else {
        this.alertCategory = this.itemErrorFilter.map(item => item.value)
      }
      this.emitApplyAlertCategory()
    })
  }

  launchFilterTimer () {
    // if a timerId already exist, dont need for relaunch it
    if (!this.timerId) {
      this.timerId = window.setTimeout(() => {
        this.dialogRefreshFilter = true
        // reset time to null cause atm, the timer do not "exist" anymore
        this.timerId = null
      }, this.timoutLong)
    }
  }

  get allAlertsType () {
    return this.itemErrorFilter.reduce((pre, curr) => {
      return [...pre, ...curr.value]
    }, [])
  }

  get allAlertsSelected () {
    return this.alertCategory.length >= this.itemErrorFilter.length
  }

  get someAlertsSelected () {
    return !this.allAlertsSelected
  }

  get iconAll () {
    if (this.allAlertsSelected) return 'check_box'
    if (this.someAlertsSelected) return 'indeterminate_check_box'
    return 'check_box_outline_blank'
  }

  get timoutLong () {
    const MINUTE_IN_MILLISECONDS = 60000
    const HOUR_IN_MILLISECONDS = MINUTE_IN_MILLISECONDS * 60
    return this.nbHoursBeforePopUp * HOUR_IN_MILLISECONDS
  }
}
