import { countDecimal, generateDigitMask, rangeNum } from '../../utils/commonUtils'

export type RuleType = (v: any) => boolean | string

export const emailPattern = new RegExp('[a-zA-Z0-9_\\.\\+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-\\.]+')

export const required = (v: any) => !!v || 'This field must be completed'
export const notEmpty = (v: any) => v.length !== 0 || 'This field must be completed'
export const requiredAllowZero = (v: any) => (!!v || v === 0) || 'This field must be completed'
export const numeric = (v: any) => !isNaN(v) || 'This field must be filled by a numeric value'
export const numericAllowUndef = (v: any) => (!v || !isNaN(v)) || 'This field must be filled by a numeric value'
export const noNegativeValue = (v: any) => (!v || v >= 0) || 'This field can not be a negative value'
export const percentage = (v: any) => (v > 0 && v <= 100) || 'This field must be between 0 and 100'
export const integer = (v: any) => (!v || Number.isInteger(Number(v))) || 'This field cannot contain floating number'
export const selectMinutes = (v: any) => (!v || isNaN(v) || (Number(v) >= 1 && Number(v) <= 59)) || 'Value must be between 1 and 59'
export const selectHours = (v: any) => (!v || isNaN(v) || (Number(v) >= 1 && Number(v) <= 23)) || 'Value must be between 1 and 23'
export const selectDays = (v: any) => (!v || isNaN(v) || (Number(v) >= 1 && Number(v) <= 6)) || 'Value must be between 1 and 6'
export const selectWeeks = (v: any) => (!v || isNaN(v) || (Number(v) >= 1 && Number(v) <= 4)) || 'Value must be between 1 and 4'
export const selectMonths = (v: any) => (!v || isNaN(v) || (Number(v) >= 1 && Number(v) <= 2)) || 'Value must be between 1 and 2'
export const maxValue255 = (v: any) => (!v || isNaN(v) || Number(v) <= 255) || 'Value must be less than 255'
export const maxValue2B = (v: any) => (!v || isNaN(v) || Number(v) <= 2000000000) || 'Value must be less than 2 000 000 000'
export const maxUnsignedIntMySql = (v: any) => (!v || isNaN(v) || Number(v) <= 4294967295) || 'Value must be less than 4 294 967 296'
export const hasNoDecimal = (v: any) => (!v || isNaN(v) || countDecimal(Number(v)) === 0) || 'Must contain 2 decimal digits max'
export const has2Decimals = (v: any) => (!v || isNaN(v) || countDecimal(Number(v)) <= 2) || 'Must contain 2 decimal digits max'
export const has3Decimals = (v: any) => (!v || isNaN(v) || countDecimal(Number(v)) <= 3) || 'Must contain 3 decimal digits max'
export const has4Decimals = (v: any) => (!v || isNaN(v) || countDecimal(Number(v)) <= 4) || 'Must contain 4 decimal digits max'
export const email = (v: any) => (!v || emailPattern.test(v)) || 'Email is not valid'
export const min01 = (v: any) => (!v || isNaN(v)) || 'Minimum value is 1'

export const digitMask: Array<String> = generateDigitMask()
export const counter = rangeNum(30).map((n: number) => {
  return (value: any) => (value == null || value === undefined || (value.toString().length <= n)) || `Max ${n}`
})

export const ruleIsBetween = (value:number, min: number, max: number, excludedMin: boolean = false, excludedMax: boolean = false) => {
  let ruleMin = excludedMin ? value <= min : value < min
  let ruleMax = excludedMax ? value >= max : value > max
  return ruleMin || ruleMax
}

export const isBetween = (value: number, min: number, max: number, customMessage: string = null, excludedMin: boolean = false, excludedMax: boolean = false) => {
  if (ruleIsBetween(value, min, max, excludedMin, excludedMax)) {
    return customMessage != null ? customMessage : `This value must be between ${min} and ${max}`
  }
}
export const minimumValue = (v: number, min: number, excludedMin: boolean = false) => {
  return (v == null || isNaN(v)) || (excludedMin ? v > min : v >= min) || `MinimumValue is ${min}`
}
export const maximumValue = (v: number, max: number, excludedMax: boolean = false) => {
  return (v == null || isNaN(v)) || (excludedMax ? v < max : v <= max) || `MaximumValue is ${max}`
}
