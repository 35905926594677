<template>
<div class="about">
  <v-row
    id="alerts-all-container"
    class="styled-scrollbar ma-0"
    >

    <v-col
    >
      <v-row no-gutters>
        <v-col cols="9">
          <AlertFilter
            @callApi="changeFilter"
          >
          </AlertFilter>
        </v-col>
        <v-col cols="3" class="tool-box-alert-container mt-2">
          <ToolBox
            v-on:open-summary="dialogSummary = true"
            v-on:open-action="actionDialog = true"
            v-on:refresh-data="refreshAlerts"
            v-on:displayLastAlert="displayLastAlert"
          >
          </ToolBox>
        </v-col>
      </v-row>
      <div class="progress-bar-alert">
        <v-progress-linear
          indeterminate
          v-show="!isApiLoaded"
        >

        </v-progress-linear>
      </div>

      <v-divider></v-divider>
      <v-container
        id="overflow-table-container"
      >
        <!-- alert prio -->
        <div class="alert-prio">
          <div
            v-for="alertGroup in alertsPrio"
            v-bind:key="alertGroup.id"
          >
            <AlertsTable
              :groupKey="alertGroup"
              :dsp="alertGroup.getDspName()"
              v-on:update="updateManagerPriority"
              v-on:remove="updateManagerPriority"
              v-on:select-alert="setSelectedAlert"
              v-on:dialog-cancel="openCancelDialog"

              :ref="generateAlertTableRef(alertGroup.id, true)"
            >
            </AlertsTable>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- alert normal -->
        <div
          v-for="alertGroup in alerts"
          v-bind:key="alertGroup.id"
        >
          <AlertsTable
            :groupKey="alertGroup"
            :dsp="alertGroup.getDspName()"
            v-on:update="updateManager"
            v-on:remove="updateManager"
            v-on:select-alert="setSelectedAlert"
            v-on:dialog-cancel="openCancelDialog"
            v-on:dialog-snooze="openSnoozeDialog"
            v-on:success="manageSuccessCommon"
            v-on:error="manageErrorCommon"
            :ref="generateAlertTableRef(alertGroup.id)"
            :class="alertIdsNotInViewport.includes(alertGroup.id) ? 'visibility-none' : ''"
          >
          </AlertsTable>
        </div>
        <v-alert style="text-align: center" type="info" :value="noMoreData">
          No more data for your search
        </v-alert>
        <v-alert type="info" :value="alerts.length <= 0">
          No data for your search
        </v-alert>

      </v-container>
    </v-col>

    <v-col
      class="shrink"
    >
      <Sidesheet
      :selectedAlert="selectedAlert"
      ref="sideSheet"
      >

      </Sidesheet>
    </v-col>
  </v-row>

    <InfoStatusDialog
      v-on:call-update="callUpdate"
      v-on:call-update-buckets="callBucketsUpdate"
      v-model="dialogInfoStatus"
      :currentDataInfoStatus="currentDataInfoStatus"
    >

    </InfoStatusDialog>

    <AlertsSummaryDialog
      v-model="dialogSummary"
    >

    </AlertsSummaryDialog>

    <AlertActionDialog
      v-model="actionDialog"
      v-on:call-buckets-update="updateManager"
      :isInProcess="isActionDialogInProcess"
    >

    </AlertActionDialog>

    <CancelAlertsDialog
      v-model="dialogCancel"
      :alert="alertToCancel"
      v-on:success="manageSuccessCommon"
      v-on:error="manageErrorCommon"
    >
    </CancelAlertsDialog>

  <SnoozeAlertsDialog
    v-model="dialogSnooze"
    :alert="alertToSnooze"
    v-on:success="manageSuccessCommon"
    v-on:error="manageErrorCommon">
  </SnoozeAlertsDialog>

</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { AlertFilter, AlertsTable, InfoStatusDialog, Sidesheet, ToolBox } from '../components/AlertingComponents'
import { clearInterval, setInterval } from 'timers'
import store from '../../store/index.js'
import { alertings } from '../../store/modules/alertings.js'
import AlertActionDialog from '../components/AlertingComponents/Form/AlertActionDialog'
import { usersMixin } from '../mixins/usersMixin'
import CancelAlertsDialog from '@/components/AlertingComponents/CancelAlertsDialog/CancelAlertsDialog'
import baseViewMixin from '@/mixins/baseViewMixin'
import SnoozeAlertsDialog from '@/components/AlertingComponents/SnoozeAlertsDialog/SnoozeAlertsDialog'

if (!store.state.alertings) store.registerModule('alertings', alertings)

export default {
  name: 'Alertings',
  props: [],
  components: {
    SnoozeAlertsDialog,
    CancelAlertsDialog,
    AlertActionDialog,
    AlertsTable,
    ToolBox,
    Sidesheet,
    AlertFilter,
    InfoStatusDialog,
    AlertsSummaryDialog: () => import('../components/AlertingComponents/AlertsSummary/AlertsSummaryDialog.vue')
  },
  mixins: [usersMixin, baseViewMixin],
  data: function () {
    return {
      apiCallRate: 0.5, // minutes
      intervalApiCall: null,
      timeoutInternalApiCall: null,
      selectedAlert: [],
      alertContainerHeight: '33em',
      dialogInfoStatus: false,
      currentDataInfoStatus: {
        type: null,
        alerts: null,
        isBucketsEdit: false
      },
      dialogSummary: false,
      actionDialog: false,
      isActionDialogInProcess: false,
      visibilityListenerName: 'visibilityListener',
      dialogCancel: false,
      /**
       * @type {AlertModel}
       */
      alertToCancel: null,
      dialogSnooze: false,
      /**
       * @type {AlertModel}
       */
      alertToSnooze: null,
      isScrollCallEnd: true,
      alertIdsNotInViewport: []
    }
  },
  created: async function () {
    await this.loadUserDataIfNeeded()
    this.launchIntervalApiCall()
    this.visibilityListener()
  },
  mounted: function () {
    this.onMounted('hidden')
    this.onScroll()
  },
  methods: {
    ...mapActions(['getAlertsData']),
    onScroll () {
      const alertContainer = document.getElementById('overflow-table-container')
      if (alertContainer) {
        alertContainer.onscroll = async () => {
          if (this.isScrollCallEnd && !this.noMoreData &&
            alertContainer.offsetHeight + alertContainer.scrollTop >= (alertContainer.scrollHeight * 0.8)) {
            this.isScrollCallEnd = false
            this.endSliceAlerts += this.baseLimit
            await this.getAlertsAndResetIntervalApiCall()
            this.isScrollCallEnd = true
          }
          this.checkAlertsInViewPorts(alertContainer)
        }
      }
    },
    async loadUserDataIfNeeded () {
      const users = this.$store.getters.getUsers
      if (!users.length) {
        await this.$store.dispatch('getUsersData')
      }
    },
    generateAlertTableRef (id, alertPriority = false) {
      if (alertPriority) {
        return 'alertsTablePriority_' + id
      }
      return 'alertsTable_' + id
    },
    checkAlertsInViewPorts (alertContainer) {
      for (let i in this.alerts) {
        let alertId = this.alerts[i].id
        let el = this.$refs[this.generateAlertTableRef(alertId)]
        if (el.length && !this.isElementInViewport(el[0].$el, alertContainer)) {
          if (!this.alertIdsNotInViewport.includes(alertId)) {
            this.alertIdsNotInViewport.push(alertId)
          }
        } else {
          let ind = this.alertIdsNotInViewport.indexOf(alertId)
          if (ind !== -1) {
            this.alertIdsNotInViewport.splice(ind, 1)
          }
        }
      }
    },
    isElementInViewport (el, alertContainer) {
      const MARGIN = 80
      return (
        (el.offsetTop + MARGIN) > alertContainer.scrollTop &&
        (el.offsetTop - MARGIN) < (alertContainer.scrollTop + alertContainer.offsetHeight)
      )
    },
    updateManagerPriority (type, alerts, remove = false, isBuckets = false) {
      this.updateManager(type, alerts, remove, isBuckets, true)
    },
    updateManager (type, alerts, remove = false, isBuckets = false, alertPriority = false) {
      // reset textInfoStatus
      this.textInfoStatus = ''

      if (this.infoStatusMustBeOpen(type, remove)) {
        this.$set(this.currentDataInfoStatus, 'isBucketsEdit', isBuckets)
        this.dialogInfoStatus = true
        this.$set(this.currentDataInfoStatus, 'type', type)
        this.$set(this.currentDataInfoStatus, 'alerts', alerts)
      } else {
        if (isBuckets) {
          this.callBucketsUpdate(type)
        } else {
          this.callUpdate(type, alerts, remove, null, null, alertPriority)
        }
      }
    },
    callUpdate: async function (type, alerts, remove = false, text = null, to = null, alertPriority = false) {
      // if no text, the user has choose missclick
      if (type === 'true_positive') {
        text = text === null || text.trim() === '' ? 'missclick' : text
      }

      const groupKey = alerts[0].keyFrom
      const response = await this.callBucketsUpdateWithGroupKey(groupKey, type, to, text, remove)
      const success = response.status === 200
      this.sendResultMessageAlertsUpdate(alerts, success, remove, type, response)

      if (success) {
        let hideDone = this.$store.getters.getCurrentFilterAlert.hideDoneAndFalsePositive
        for (let i in alerts) {
          if (hideDone) {
            this.deleteAlertsFromClient(alerts[i].identityId, alerts[i].error_type, alertPriority)
          } else {
            this.updateAlertInClient(alerts[i], type, remove, text, this.$store.getters.getCurrentUser)
          }
        }
      }
    },
    sendResultMessageAlertsUpdate (alerts, success, remove, type, response) {
      let message = ''
      let dsp = alerts[0].getDspName()
      let clientValue = this.$getClientValue(dsp)
      let clientId = alerts[0].group_key.client_id
      let ioValue = this.$getIoField(dsp, true)
      let IO = alerts[0].group_key.insertion_order_id

      if (success) {
        let identityAlert = `with the ${ioValue} ${IO} [DSP : ${dsp}, ${clientValue} : ${clientId} ]`
        message = `The alert(s) ${identityAlert} have been well saved in ${type} status`
        if (remove) {
          message = `The status ${type} has been well removed from the alert(s) ${identityAlert}`
        }
        this.$emit('ask-snackbar', message)
      } else {
        message = `Error when updated the alert(s) with the ${clientValue} ${IO} [${dsp}]. Code Error : ${response}.`
        this.$emit('ask-snackbar', message, 'error')
      }
    },
    infoStatusMustBeOpen (type, remove) {
      return ['help', 'false_positive', 'attributed', 'fixed', 'true_positive'].indexOf(type) !== -1 && remove === false
    },
    updateAlertInClient (alert, type, remove, text, user) {
      if (remove) {
        this.$set(alert, type, null)
      } else {
        if (!alert.is(type)) {
          if (['attributed', 'fixed', 'true_positive'].indexOf(type) !== -1) {
            this.$set(alert, type, { name: this.getCurrentUser, date: (new Date()), text: text, user: user })
          } else if (['false_positive', 'help'].indexOf(type) !== -1) {
            this.$set(alert, type, { name: this.getCurrentUser, date: (new Date()), text: text })
          } else {
            this.$set(alert, type, { name: this.getCurrentUser, date: (new Date()) })
          }
        }
      }
    },
    setSelectedAlert: function (alert) {
      if (!this.$commonUtils.objectIsEmpty(this.selectedAlert)) {
        let previousLine = document.getElementById(this.selectedAlert[0].id)
        if (previousLine) {
          previousLine.classList.remove('selected-alert-class')
        }
      }

      let currentLine = document.getElementById(alert[0].id)

      if (currentLine) {
        currentLine.classList.add('selected-alert-class')
      }

      this.selectedAlert = alert
    },
    launchIntervalApiCall: function () {
      // to prevent multiple interval in the same time
      this.stopIntervalApiCall()
      this.checkNewAlerts()
      this.intervalApiCall = setInterval(() => {
        this.checkNewAlerts()
        console.log('Api have been called')
      }, this.$minutesMilliseconds(this.apiCallRate))
    },
    checkNewAlerts () {
      // add marge for security
      let minutes = this.apiCallRate + 0.2
      this.$store.dispatch('callCheckNewAlerts', { currentUserMail: this.getCurrentUserAvailableMail, minutes: minutes })
      console.warn('check new alerts')
    },
    stopIntervalApiCall: function () {
      if (this.intervalApiCall !== null) {
        clearInterval(this.intervalApiCall)
        console.log('Interval call of the api has been stopped')
      }
    },
    stoptimeoutApiCall: function () {
      if (this.timeoutInternalApiCall !== null) {
        clearTimeout(this.timeoutInternalApiCall)
      }
    },
    changeFilter () {
      this.refreshValues()
      this.getAlertsWithCurrentFilter()
    },
    async getAlertsWithCurrentFilter () {
      await this.getAlertsData()
    },
    refreshValues () {
      this.alertIdsNotInViewport = []
      this.endSliceAlerts = 0
      this.$store.commit('loadAlerts', [])
      this.noMoreData = false
    },
    refreshAlerts () {
      this.refreshValues()
      this.getAlertsAndResetIntervalApiCall()
    },
    displayLastAlert (search) {
      this.$store.commit('resetCurrentFilterAlerts')
      let currentFilter = this.$store.getters.getCurrentFilterAlert
      currentFilter.searched = search
      this.changeFilter()
    },
    async getAlertsAndResetIntervalApiCall () {
      await this.getAlertsWithCurrentFilter()
      this.launchIntervalApiCall()
    },
    updateOnAppFocus () {
      let state = document.visibilityState

      if (state === 'hidden') {
        console.log('Visibility is hidden. Stop calling the api')
        this.stopIntervalApiCall()
      } else if (state === 'visible') {
        let currentMilliseconds = new Date().getTime()
        let timeElapsedSinceLastUpdate = currentMilliseconds - this.getLastUpdate

        if (timeElapsedSinceLastUpdate < this.$minutesMilliseconds(this.apiCallRate)) {
          console.log(`Time elapsed since last update is less than ${this.apiCallRate} minutes. Don't need to update alerts now`)

          let nextUpdate = this.$minutesMilliseconds(this.apiCallRate) - timeElapsedSinceLastUpdate

          console.log(`Next update will be launched in ${this.$millisecondsInMinutes(nextUpdate)} minutes`)

          this.stoptimeoutApiCall()

          this.timeoutInternalApiCall = setTimeout(() => {
            this.launchIntervalApiCall()
          }, nextUpdate)

          return
        }

        console.log('Visibility is visible. Reset the call to the api')
        this.getAlertsAndResetIntervalApiCall()
      }
    },
    /**
     * add event listener to the visiblity
     * Stop calling the api when user isn't focused on the app
     * Update alert when user is focusing on the app
     * Check state.lastUpdate before call for avoid to call the api before the interval apiCallRate
     * A status of the event Listener is stocked in the store ('alertingsListener') for avoid to call the event 2 times
     */
    visibilityListener () {
      if (this.$store.getters.getAlertingsListener.indexOf(this.visibilityListenerName) === -1) {
        this.$store.commit('addAlertingsListener', this.visibilityListenerName)
        document.addEventListener(this.visibilityListenerName, this.updateOnAppFocus.bind(this))
      }
    },
    async callBucketsUpdate (status, text = null) {
      this.isActionDialogInProcess = true
      let data = {
        groupKeyIds: this.$store.getters.getBucketsAlertsId,
        status: status,
        content: text
      }
      let response = await this.$store.dispatch('bucketsUpdateAlert', data)
      this.callBucketsDone(response.status)
    },
    async callBucketsUpdateWithGroupKey (groupKeyIds, status, to = null, content = null, remove = false) {
      let data = {
        groupKeyIds: [groupKeyIds],
        status: status,
        content: content,
        to: to,
        remove: remove
      }
      return this.$store.dispatch('bucketsUpdateAlert', data)
    },
    callBucketsDone (status) {
      this.resetCheckBoxAlertsTable()
      let message = ''

      // eslint-disable-next-line eqeqeq
      if (status == 200) {
        this.refreshAlerts()
        message = 'BUCKETS EDIT : Success'
        this.$emit('ask-snackbar', message)
      } else {
        message = 'BUCKETS EDIT : FAIL'
        this.$emit('ask-snackbar', message, 'error')
      }
      this.isActionDialogInProcess = false
      this.actionDialog = false
    },
    resetCheckBoxAlertsTable () {
      this.$store.commit('resetBucketAlertsId')
    },
    openCancelDialog (item) {
      if (!Array.isArray(item) || !item.length) {
        throw TypeError('item must be a array of alert model')
      }
      this.alertToCancel = item[0]
      this.dialogCancel = true
    },
    manageErrorCommon (message) {
      this.$emit('ask-snackbar', message, 'error')
    },
    manageSuccessCommon (message, removedAlert) {
      this.$emit('ask-snackbar', message)
      this.deleteAlertsFromClient(removedAlert.identityId, removedAlert.error_type)
    },
    openSnoozeDialog (item) {
      if (!Array.isArray(item) || !item.length) {
        throw TypeError('item must be a array of alert model')
      }
      this.alertToSnooze = item[0]
      this.dialogSnooze = true
    },
    deleteAlertsFromClient (identityId, errorType, alertPriority = false) {
      const groupKeys = alertPriority
        ? this.$store.getters.getAlertsPrio
        : this.$store.getters.getAlerts
      const groupKey = groupKeys.filter(item => item.id === identityId)
      const index = groupKeys.indexOf(groupKey[0])
      if (groupKey.length) {
        this.$delete(groupKey[0].alerts, errorType)
        // if the groupKey contain no other errorType, delete it
        if (!Object.keys(groupKey[0].alerts).length) {
          this.$delete(groupKeys, index)
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getApiStatus',
      'isApiLoaded',
      'getCurrentFilterAlert',
      'getCurrentUser',
      'getLastUpdate'
    ]),
    alerts: {
      get: function () {
        return this.$store.getters.getAlerts
      }
    },
    alertsPrio: {
      get: function () {
        return this.$store.getters.getAlertsPrio
      }
    },
    endSliceAlerts: {
      get: function () {
        return this.$store.getters.getEndSliceAlert
      },
      set: function (value) {
        return this.$store.commit('setEndSliceAlerts', value)
      }
    },
    baseLimit: {
      get: function () {
        return this.$store.getters.getBaseLimitAlertings
      }
    },
    noMoreData: {
      get: function () {
        return this.$store.getters.getNoMoreData
      },
      set: function (value) {
        return this.$store.commit('setNoMoreData', value)
      }
    },
    newAlerts: {
      get: function () {
        return this.$store.getters.getNewAlerts
      }
    }
  },
  watch: {
  },
  beforeDestroy () {
    this.stoptimeoutApiCall()
    this.stopIntervalApiCall()
    document.removeEventListener('visibilitychange', this.updateOnAppFocus.bind(this))
    if (this.$apiCaller.cancelCall('alerts')) {
      console.log('Call to alert has been right cancelled')
    }
    this.setMainOverflow('auto')
  },
  beforeRouteLeave (to, from, next) {
    this.stoptimeoutApiCall()
    this.stopIntervalApiCall()
    document.removeEventListener('visibilitychange', this.updateOnAppFocus.bind(this))
    this.$store.commit('removeAlertingsListener', this.visibilityListenerName)
    next()
  }
}
</script>

<style>
#overflow-table-container {
  max-height: 80vh;
  overflow: auto;
  padding-top: 6px;
  padding-bottom: 10em;
}

.selected-alert-class {
  background: #EAEAEA;
  /*border: 1px solid ghostwhite;*/
  border: 1px solid gold;
}

.progress-bar-alert {
  height: 0em;
  background: white;
}
.progress-bar-alert .v-progress-linear {
  margin: 0;
}

.tool-box-alert-container {
  padding-top: 1em;
}

.visibility-none {
  content-visibility: hidden;
}

/* Large screens ----------- */
@media only screen
and (min-width : 1824px) {
  #overflow-table-container {
    height: 88vh;
  }
}
</style>
