<template>
  <div>
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'LoadingTemplate',
  props: [],
  components: {

  },
  data: function () {
    return {
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
