<template>
    <v-container class="alert-filter-container">
      <v-row class="alert-filter-top">
        <v-col class="filter-top-am" cols="4">
          <div v-show="!getSwitchDebugger" class="user-filter-div">
            <v-autocomplete
              :value="currentFilter.am"
              :items="getOnlyAmMail.sort()"
              @change="emitCallApi"
              chips
              :label="`Select ${!getSwitchDebugger ? 'am' : 'debugger'}`"
              multiple
              id="autocomplete-user"
              ref="auto-user"
            >
              <template v-slot:selection="data">
                <v-chip
                  :input-value="data.selected"
                  close
                  class="chip-alert-filter"
                  @click:close="removeAndCallApiAm(data.item)"
                >
                  {{ data.item.substring(0, data.item.indexOf('@')) }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item-content v-text="data.item.substring(0, data.item.indexOf('@'))"></v-list-item-content>
                <v-btn color="dark" @click="selectOnly(data.item)">only</v-btn>
              </template>
            </v-autocomplete>
          </div>

          <div v-show="getSwitchDebugger" class="debugger-filter-div">
            <v-autocomplete
              :value="currentFilter.debugger"
              :items="getDebuggersName"
              @change="emitCallApiDebugger"
              chips
              :label="`Select ${!getSwitchDebugger ? 'am' : 'debugger'}`"
              multiple
              id="autocomplete-debugger"
              ref="auto-debugger"
            >
              <template v-slot:selection="data">
                <v-chip
                  :input-value="data.selected"
                  close
                  class="chip-alert-filter-debugger"
                  @click:close="removeAndCallApiDebugger(data.item)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item-content v-text="data.item"></v-list-item-content>
                <v-btn color="dark" @click="selectOnly(data.item, 'debugger')">only</v-btn>
              </template>
            </v-autocomplete>

          </div>
        </v-col>
        <v-col class="filter-top-search"  cols="4">
          <v-text-field
            :value="searchField"
            append-icon="search"
            label="Search"
            hide-details
            id="search-bar-alert"
            @input="emitCallSearchAfterTimeout"
          >
          </v-text-field>
        </v-col>
        <v-col class="filter-top-hide" cols="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-checkbox
                :label="'Hide Done / FP'"
                @change="emitCallApiHideDoneFalsePositive"
                :input-value="currentFilter.hideDoneAndFalsePositive"
                v-on="on"
                :disabled="disableDoneFp"
              >
              </v-checkbox>
            </template>
            <span>Hide GROUP KEY where ALL the alerts are in done and/or false positive. Only available when ALL is checked </span>
          </v-tooltip>
        </v-col>

        <v-col cols="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="expandFilter = !expandFilter">
                <v-icon>{{ expandFilter ? 'remove_circle_outline' : 'control_point' }}</v-icon>
              </v-btn>
            </template>
            <span>
            Open complex filter
          </span>
          </v-tooltip>
        </v-col>

        <v-col v-if="$store.getters.isUserDebugger" cols="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                @click="showDebuggerFilter = !showDebuggerFilter"
                text
                icon
                v-on="on"
              >
                <v-icon>{{!showDebuggerFilter ? 'expand_more' : 'expand_less'}}</v-icon>
              </v-btn>
            </template>
            <span>Show debugger filter</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row no-gutters class="alert-filter-bottom justify-start" v-show="$store.getters.isUserDebugger && showDebuggerFilter">
        <v-col cols="4" class="filter-mode">
          <v-col class="py-0" v-if="$store.getters.isUserDebugger">
            <v-switch
              v-model="switchAuto"
              :label="`Mode : ${!getSwitchDebugger ? 'am' : 'debugger'}`"
              class="switch-mode"
            ></v-switch>
          </v-col>
        </v-col>
        <v-col cols="6">
          <v-radio-group @change="emitCallApiAttributed($event)" :value="defaultToShowValue">
            <v-row class="justify-center align-center">
              <v-col class="filter-not-attributed">
                <v-tooltip bottom v-if="$store.getters.isUserDebugger">
                  <template v-slot:activator="{ on }">
                    <v-radio
                      :label="'Not attributed'"
                      :value="NOT_ATTRIBUTED"
                      v-on="on"
                    >
                    </v-radio>
                  </template>
                  <span>Show only not attributed alerts</span>
                </v-tooltip>
              </v-col>
              <v-col class="filter-attributed">
                <v-tooltip bottom v-if="$store.getters.isUserDebugger">
                  <template v-slot:activator="{ on }">
                    <v-radio
                      :label="'Attributed'"
                      :value="ATTRIBUTED"
                      v-on="on"
                    >
                    </v-radio>
                  </template>
                  <span>Show only attributed alerts</span>
                </v-tooltip>
              </v-col>
              <v-col class="filter-all">
                <v-tooltip bottom v-if="$store.getters.isUserDebugger">
                  <template v-slot:activator="{ on }">
                    <v-radio
                      :label="'All'"
                      :value="ALL"
                      v-on="on"
                    >
                    </v-radio>
                  </template>
                  <span>Show all alerts</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-slide-y-transition>
          <AlertExpandFilter
            v-show="expandFilter"
            v-on:baselineFilterUpdated="emitCallApiBaseline"
            v-on:stratLvlUpdated="emitCallApiStratLvl"
            v-on:alertCategoryUpdated="emitCallApiAlertCategory"
            v-on:optiAutoFilterUpdated="emitCallApiOptiAuto"
          >
          </AlertExpandFilter>
        </v-slide-y-transition>
      </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import AlertExpandFilter from '@/components/AlertingComponents/FilterBar/AlertExpandFilter'

const ATTRIBUTED = true
const NOT_ATTRIBUTED = false
const ALL = null

export default {
  name: 'AlertFilter',
  props: [],
  components: {
    AlertExpandFilter

  },
  data: function () {
    return {
      input: {
        status: ['ongoing', 'done', 'false_positive', 'help']
      },
      style: {
        outline: false
      },
      timeoutHandle: null,
      showDebuggerFilter: false,
      defaultToShowValue: ALL,
      currentToShowValue: ALL,
      ATTRIBUTED: ATTRIBUTED,
      NOT_ATTRIBUTED: NOT_ATTRIBUTED,
      ALL: ALL,
      ERROR_TYPE_REGEX: /^[0-9]\.[0-9]\.[0-9]$/,
      expandFilter: false,
      searchTimeOutId: null
    }
  },
  created: function () {
    this.getCurrentSearchFromQueryOrStore()
  },
  mounted: function () {

  },
  methods: {
    remove (item, userType = 'am') {
      if (['am', 'debugger'].indexOf(userType) === -1) {
        throw Error(`userType param must be one am or debugger`)
      }
      const index = this.currentFilter[userType].indexOf(item)

      if (index >= 0) {
        this.$delete(this.currentFilter[userType], index)
      }
    },
    removeAndCallApiAm (item) {
      this.remove(item, 'am')
      this.emitCallApi(this.currentFilter.am)
    },
    removeAndCallApiDebugger (item) {
      this.remove(item, 'debugger')
      this.emitCallApi(this.currentFilter.debugger)
    },
    /**
     *
     * @param amName {string}
     * @param userType {string} must be 'am' or 'debugger'
     */
    selectOnly (amName, userType = 'am') {
      if (['am', 'debugger'].indexOf(userType) === -1) {
        throw Error(`userType param must be one am or debugger`)
      }
      // just remove all am, the click on the btn will naturally add the selected am
      this.$nextTick(function () {
        this.updateFilter(userType, [])
      })
    },
    setQueryStringProcess () {
      let queryString = this.$setQueryStringAlertings(this.currentFilter.searched, this.currentFilter.am, this.currentFilter.hideDoneAndFalsePositive)
      history.replaceState({}, 'history-internal-interface-alertings', queryString)
    },
    getCurrentSearchFromQueryOrStore () {
      if (this.$route.query.q_search !== undefined) {
        this.updateFilterSearch(this.$route.query.q_search)
      }

      if (this.$route.query.am !== undefined && this.$route.query.am !== null && this.$route.query.am.trim() !== '') {
        const withoutDuplicate = new Set([...this.$route.query.am.split(';')])
        this.updateFilter('am', [...withoutDuplicate])
      }

      let hideComputed = true

      if (this.$route.query.hide_done_and_false_positive !== undefined) {
        hideComputed = Boolean(Number(this.$route.query.hide_done_and_false_positive))
      }
      this.updateFilter('hideDoneAndFalsePositive', hideComputed)

      this.$emit('callApi')
    },
    /**
     * @param key {'am', 'searched', 'hideDoneAndFalsePositive', 'error_type',
     * 'debugger', 'baseline_type' | 'strat_lvl' | 'error_type_category' | 'opti_auto'}
     * @param value {any}
     */
    updateFilter (key, value) {
      let currentFilter = this.currentFilter
      currentFilter[key] = value
      this.$store.commit('updateAlertFilter', currentFilter)
    },
    /**
     * Filter search have many mode.
     * Classic search : no particularity in searched string, will update searched
     * Error type search : when the searched string start by 'error_type:', will update error_type filter
     * Return true if the filter has been right updated, otherwise return false
     * Only one mode can be active in the same time (using one will update the filter at null for the other)
     * @param search {string}
     * @return {boolean}
     */
    updateFilterSearch (search) {
      let trimSearch = search.trim()
      if (trimSearch.indexOf('error_type:') === 0) {
        let errorType = trimSearch.split(':')[1].trim()
        let r = new RegExp(this.ERROR_TYPE_REGEX)
        if (r.test(errorType)) {
          this.updateFilter('error_type', errorType)
          this.updateFilter('searched', null)
        } else {
          return false
        }
      } else {
        this.updateFilter('error_type', null)
        this.updateFilter('searched', trimSearch)
      }
      return true
    },
    emitCallApi (mailList) {
      this.updateFilter('am', mailList)
      this.$emit('callApi')
    },
    emitCallApiDebugger (list) {
      this.updateFilter('debugger', list)
      this.$emit('callApi')
    },
    emitCallSearchAfterTimeout (search) {
      if (this.searchTimeOutId != null) {
        clearTimeout(this.searchTimeOutId)
      }
      this.searchTimeOutId = setTimeout(() => {
        this.emitCallApiSearch(search)
        this.searchTimeOutId = null
      }, 500)
    },
    emitCallApiSearch (search) {
      if (this.updateFilterSearch(search)) {
        this.$emit('callApi')
      }
    },
    emitCallApiHideDoneFalsePositive () {
      this.updateFilter('hideDoneAndFalsePositive', !this.currentFilter.hideDoneAndFalsePositive)
      this.$emit('callApi')
    },
    emitCallApiAttributed (attributed) {
      let currentFilter = this.currentFilter
      currentFilter.attributed = attributed
      // When clicking on attributed or not attributed, we do not want hideDone
      currentFilter.hideDoneAndFalsePositive = false
      this.$store.commit('updateAlertFilter', currentFilter)
      this.$emit('callApi')
    },
    emitCallApiBaseline (baselineType) {
      if (baselineType === 'N/A') {
        baselineType = null
      }
      this.updateFilter('baseline_type', baselineType)
      this.$emit('callApi')
    },
    emitCallApiStratLvl (stratLvl) {
      this.updateFilter('strat_lvl', stratLvl)
      this.$emit('callApi')
    },
    emitCallApiAlertCategory (alertCategory) {
      this.updateFilter('error_type_category', alertCategory)
      this.$emit('callApi')
    },
    emitCallApiOptiAuto (optiAuto) {
      this.updateFilter('opti_auto', optiAuto)
      this.$emit('callApi')
    }
  },
  computed: {
    ...mapGetters([
      'getSwitchDebugger',
      'getDebuggersName',
      'getOnlyAmMail'
    ]),
    currentFilter: {
      get: function () {
        return this.$store.getters.getCurrentFilterAlert
      }
    },
    switchAuto: {
      get () {
        return this.$store.getters.getSwitchDebugger
      },
      set (value) {
        this.$store.commit('setSwitchDebugger', value)
      }
    },
    searchField () {
      if (this.currentFilter.searched) {
        return this.currentFilter.searched
      } else if (this.currentFilter.error_type) {
        return `error_type:${this.currentFilter.error_type}`
      }
      return ''
    },
    disableDoneFp () {
      return !_.isNil(this.currentFilter.attributed)
    }
  },
  watch: {
    'currentFilter': {
      deep: true,
      immediate: true,
      handler: function (currentFilter) {
        this.$store.commit('resetEndSlice')
        this.setQueryStringProcess()
      }
    },
    'switchAuto': {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.$store.commit('resetEndSlice')
      }
    }
  }
}
</script>

<style>
.user-filter-div,
.debugger-filter-div {
  width: 100%;
}
.alert-filter-container {
  width: 100%;
  /*height: 11em;*/
  background: white;
  padding-top: 0.2em;
  padding-left: 1em;
  padding-right: 1em;
  border-right: 0.4px solid;
  border-color: rgba(0,0,0,0.12);
  padding-bottom: 0;
}

.search-bar-alert-div {
  padding-top: 2em;
}

.flex-alert-filter {
  padding: 1em;
}

.alert-filter-container span.chip-alert-filter {
  border-radius: 3px;
  background: #1697F6 !important;
  color: white;
}

.alert-filter-container .chip-alert-filter-debugger {
  border-radius: 3px;
  background: forestgreen !important;
  color: white;
}

.alert-filter-container .chip-alert-filter .v-chip__close > .v-icon {
  opacity: 0.8;
}
.alert-filter-container .chip-alert-filter .v-chip__close > .v-icon:hover {
  opacity: 1;
}

.alert-filter-container label.v-label {
  font-size: 10px;
}
.alert-filter-container span.v-chip__content {
  font-size: 10px;
}

.filter-checkbox-layout .v-input {
  margin-top: 0;
  height: 2.2em;
}

.switch-mode {
  /*margin-top: 2em;*/
}

.user-filter-div .v-input.v-text-field.v-select.v-select--chips.v-autocomplete.theme--light {
  padding-top: 4px;
}

.layout.alert-filter-top div {
  padding-left: 1px;
  padding-right: 1px;
}

.row.alert-filter-bottom {
  height: 3em;
}

.alert-filter-bottom .v-input.v-input {
  margin-top: 0;
}

/* Large screens ----------- */
@media only screen
and (min-width : 1824px) {
  .alert-filter-container label.v-label {
    font-size: 12px;
  }
  .alert-filter-container span.v-chip__content {
    font-size: 12px;
  }
}

</style>
