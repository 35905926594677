
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class SimpleDialog extends Vue {
  @Prop({ required: false, default: false }) model: boolean
  @Prop({ required: false, default: '' }) headerContent: string
  @Prop({ required: false, default: '600px' }) maxWidth: string
  @Prop({ required: false, default: 'center center' }) origin: string
  @Prop({ required: false, default: 'slide-x-transition' }) transition: string

  show: boolean = false

  mounted () {
    this.show = this.model
  }

  closeDialog () {
    this.show = false
    this.$emit('closeSimpleDialog')
  }

  @Watch('model')
  onModelChange () {
    this.show = this.model
  }
}
