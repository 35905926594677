/**
 * contain helper functions for access info and/or data about array of alert
 */
export default {
  generateDataFormLinkToSurcouche,
  generateSimpleLinkToSurcouche
}

// TODO write unit test
/**
 * create a dataForm link to the surcouche
 * a dataform link open a form is the insertion_order don't exist in the surcouche
 * otherwise, just enter the insertion_order in the search bar of the surcouche
 * @param alert {AlertModel}
 * @param frontHost {string}
 * @returns {string}
 */
function generateDataFormLinkToSurcouche (alert, frontHost) {
  let dataForm = {
    group_key: alert.group_key,
    group_name: alert.data.group_name !== undefined ? alert.data.group_name : 'NC'
  }
  let dataFormStringified = JSON.stringify(dataForm)
  return `${frontHost}/${alert.getDspName()}?data_form=${encodeURIComponent(dataFormStringified)}`
}
/**
 * create a simple link to the surcouche with a alert
 * @param alert {AlertModel}
 * @param frontHost {string} : the server (depend of the env)
 * @returns {string}
 */
function generateSimpleLinkToSurcouche (alert, frontHost) {
  return `${frontHost}/${alert.getDspName()}?q_search=${alert.getInsertionOrder()}`
}
