<template>
  <BaseAlertTemplate
    :detailedData="detailedDataComputed"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'

export default {
  name: 'FuckFuckFuckFuck',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        {
          label: 'list of de-activated adgroupds',
          value (alert) {
            if (alert && alert.data && alert.data.deactivated_adgroups &&
              Array.isArray(alert.data.deactivated_adgroups)) {
              return `${this.$commonUtils.htmlEntitiesList(alert.data.deactivated_adgroups).join(', ')}`
            }
          },
          endLabel: '',
          roundValue: false,
          isPerc: false,
          colorize: false,
          isBold: false
        }
      ],
      settingsData: [
      ],
      algoStrategyData: [
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {
    detailedDataComputed () {
      if (this.alert && this.alert.getDspName() === this.$THETRADEDESK) {
        return this.detailedData
      }
      return []
    }
  },
  watch: {

  }
}
</script>

<style>
</style>
